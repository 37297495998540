.industries {
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #003A5A;

  @include phone {
    font-size: 14px;
    line-height: 17px;
  }

  &__container {
    padding-top: 70px;
    padding-bottom: 70px;

    @include laptop {
      padding-top: 54px;
      padding-bottom: 56px;
    }

    @include phone {
      padding-top: 55px;
      padding-left: 32px;
      padding-right: 27px;
      padding-bottom: 39px;
    }
  }
  &__content {
    max-width: 835px;
    margin: 0 auto;
    margin-left: 94px;

    @include laptop {
      margin-left: 0;
    }
  }

  &__heading {
    font-weight: 600;
    font-size: 22px;
    @include phone {
      font-size: 18px;
      line-height: 18px;
    }
  }

  ul, li {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #003A5A;

    @include phone {
      font-size: 14px;
      line-height: 17px;
    }
  }

  ul {
    margin-top: 33px;
    margin-bottom: 55px;
    display: flex;
    flex-wrap: wrap;

    @include laptop {
      margin-bottom: 30px;
    }

    @include phone {
      margin: 35px 0;
    }
  }

  li {
    position: relative;
    padding-left: 28px;
    margin-bottom: 25px;
    min-width: 40%;
    max-width: 60%;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: url("../img/list-arr-a-serv.svg");
      position: absolute;
      left: 0;
      top: 4px;
    }

    @include laptop {
      min-width: auto;
      max-width: none;
      width: 50%;
      padding-right: 10px;
    }

    @include phone {
      width: 100%;
      margin-bottom: 32px;
    }
  }
}
