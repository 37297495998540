.news {

  @include phone {
    padding-bottom: 290px;
  }

  //video {
  //  position: absolute;
  //  top: 0;
  //  left: 50%;
  //  min-width: 100%;
  //  min-height: 100%;
  //  width: auto;
  //  height: auto;
  //  transform: translateX(-50%);
  //  background-size: cover;
  //  -webkit-transition: 1s opacity;
  //  transition: 1s opacity;
  //
  //  //top: 50%;
  //  //left: 50%;
  //  //min-width: 100%;
  //  //min-height: 100%;
  //  //width: auto;
  //  //height: auto;
  //  ////z-index: 1;
  //  //transform: translateX(-50%) translateY(-50%);
  //  //background-size: cover;
  //  //transition: 1s opacity;
  //
  //  @include phone {
  //    //height: 289px;
  //    //overflow: hidden;
  //    //transform: none;
  //    //left: 0;
  //    //width: auto;
  //    //top: 0;
  //    //min-height: auto;
  //    //min-width: auto;
  //
  //
  //    transform: none;
  //    position: absolute;
  //    z-index: 1;
  //    top: 0;
  //    left: 0;
  //    width: 100%;
  //    height: 100%;
  //    object-fit: cover;
  //    object-position: 50% 100%;
  //    transition: all .25s linear;
  //  }
  //}

  background: linear-gradient(147.27deg, #00BADD 1.83%, rgba(255, 255, 255, 0) 133.53%), #0573A2;

  &__container {
    min-height: 733px;
    display: flex;
    justify-content: center;

    @include laptop {
      display: block;
    }
  }

  &__heading {
    font-weight: 300;
    font-size: 45px;
    line-height: 45px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-bottom: 48px;
    width: 100%;
  }
  &__right {
    width: 50%;
    //height: 100%;

    @include laptop {
      width: 100%;
    }

  }
  &__left {
    width: 50%;
    height: 100%;
    padding: 74px 48px 59px 160px;
    display: flex;
    justify-content: flex-end;

    @include laptop {
      width: 100%;
      padding: 61px 20% 56px;
    }
    @include tablets {
      padding: 61px 60px 56px 77px;
    }

    @include phone {
      padding: 61px 12px 35px 36px;
    }

  }

  &__left-container {
    max-width: 720px;
  }

  &__all {
    position: relative;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.02em;
    padding-bottom: 0;
    border-bottom: 1px solid white;
    color: #FFFFFF;
    transition: 0.3s;

    &:hover {
      border-bottom-color: transparent;
    }

    &:after {
      position: absolute;
      content: url("../img/right-arr.svg");
      top: 4px;
      right: -23px;
    }
  }

  &__item {
    margin-bottom: 47px;
  }

  &__date {
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-bottom: 17px;
  }

  &__text {
    font-size: 22px;
    line-height: 27px;
    letter-spacing: 0.02em;
    margin-bottom: -2px;
    color: #FFFFFF;
    padding-bottom: 0;
    border-bottom: 1px solid white;
    transition: 0.3s;

    &:hover {
      border-bottom-color: transparent;
    }
  }
}

