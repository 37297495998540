.about-prons {
  &__container {
    padding-top: 80px;
    padding-bottom: 70px;

    @include laptop {
      padding-top: 55px;
      padding-bottom: 0;
    }

    @include phone {
      padding-bottom: 21px;
    }
  }

  &__list {
    display: flex;

    @include laptop {
      flex-wrap: wrap;
    }
  }
  &__item {
    width: 25%;
    text-align: center;
    padding-right: 20px;

    &_large {
      width: 50%;

      .about-prons {
        &__text {
          font-weight: normal;
          max-width: none;
          font-size: 18px;
          line-height: 22px;
          text-align: center;
          letter-spacing: 0.02em;
          color: #003A5A;

          @include phone {
            font-size: 14px;
            line-height: 17px;
          }
        }
      }
    }

    @include laptop {
      width: 50%;
      margin-bottom: 37px;

      &:nth-child(even) {
        padding-right: 0;
      }
    }

    &:last-child {
      padding-right: 0;
    }
  }

  &__img {
    justify-content: center;
    margin-bottom: 21px;

    @include phone {
      margin-bottom: 16px;
    }
  }
  &__text {
    font-weight: 600;
    font-size: 22px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #003A5A;
    margin: 0 auto;
    max-width: 260px;

    @include phone {
      font-size: 18px;
      line-height: 18px;
    }
  }
}
