.highlight {
  background-color: #F1F1F1;
  border-radius: 4px;
  padding: 0 48px 25px 40px;
  position: relative;
  z-index: 1;

  @include laptop {
    padding: 0 44px 41px 38px;
  }

  @include tablets {
    padding: 0 35px 41px 29px;
  }

  @include phone {
    padding: 0 20px 26px 19px;
  }

  &__name-wrapper {
    padding: 7px 12px;
    display: inline-block;
    text-transform: uppercase;
    margin-bottom: 20px;
    position: relative;

    &_smaller {
      margin-bottom: -19px;

      @include phone {
        margin-bottom: 10px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      background: linear-gradient(173.73deg, #008C95 3.11%, rgba(255, 255, 255, 0) 137.01%), #00C4C4;
      opacity: 0.9;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;

    }
  }

  &__name {
    display: inline;
    position: relative;
    z-index: 1;

    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }

  &__text {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #003A5A;

    @include phone {
      font-size: 14px;
      line-height: 17px;
    }

    p {
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.02em;
      color: #003A5A;
      margin: 0;
      margin-bottom: 10px;

      @include phone {
        font-size: 14px;
        line-height: 17px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__slider-container {
    display: flex;
    align-items: center;

    @include phone {
      margin-left: -20px;
      width: calc(100% + 80px);
    }
  }

  &__slider {
    overflow: hidden;
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__slide {
    position: relative;

    img {
      max-width: 100%;
    }
  }
  &__controls {
    margin-left: 30px;
    margin-top: -11px;
    width: 68px;
    cursor: pointer;
    .swiper-button-prev-proj, .swiper-button-next-proj {
      outline: none;
      width: 68px;
      height: 52px;

      &:hover {
        svg {
          rect {
            stroke: rgba(#0573A1, 0.5);
          }
          path {
            stroke:rgba(#0573A1, 0.5);
          }
        }
      }
      svg {
        rect {
          stroke: #0573A1;
        }
        path {
          stroke: #0573A1;
        }
      }
    }

    .swiper-button-next-proj {
      transform: rotate(180deg);
      margin-top: 10px;
    }

    @include phone {
      display: none;
    }
  }
}
