.news-card {
  &__date {
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
  }

  &__left {
    color: #003A5A;
    letter-spacing: 0.02em;
    width: 17%;
    padding-right: 73px;

    @include laptop {
      padding-right: 25px;
    }

    @include phone {
      width: 100%;
      padding-right: 0;
      margin-bottom: 15px;
    }
  }

  &__right {
    color: #003A5A;
    letter-spacing: 0.02em;
    width: 83%;

    &:hover {
      .news-card__title {
        text-decoration-color: transparent;
      }
    }
  }

  &__title {
    font-size: 22px;
    line-height: 22px;
    text-decoration-line: underline;
    margin-bottom: 15px;

    @include phone {
      font-size: 18px;
      line-height: 20px;
    }
  }

  &__text {
    font-size: 18px;
    line-height: 18px;

    @include phone {
      font-size: 14px;
      line-height: 14px;
    }
  }

  &__wrapper {
    display: flex;

    @include phone {
      display: block;
    }
  }

}
