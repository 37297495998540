.feature-text {
  display: none;
  width: 100%;

  &:first-child {
    display: block;
  }

  ul {
    li {
      position: relative;
      z-index: 1;
      padding-left: 30px;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.02em;
      color: #FFFFFF;
      margin-bottom: 27px;
      opacity: 1;

      &:last-child {
        margin-bottom: 74px;

        @include laptop {
          margin-bottom: 35px;
        }
      }

      &:before {
        content: url("../img/left-deco-el.svg");
        width: 14px;
        height: 14px;
        position: absolute;
        top: 3px;
        transform: scale(0.6);
        left: 10px;
      }
    }
  }

  &__btn {
    z-index: 1;
    position: relative;
    margin: 0 auto;
  }

}

.feature-texts {
  border-radius: 4px;
  border: 1px solid white;
  padding: 41px 66px 44px 71px;
  min-height: 581px;
  position: relative;
  z-index: 1;
  width: 100%;

  //&_about {
  //
  //  min-height: 300px;
  //  padding: 38px 66px;
  //
  //  @include laptop {
  //    min-height: 270px !important;
  //    padding: 55px !important;
  //  }
  //  .feature-text {
  //    font-size: 25px;
  //    line-height: 30px;
  //    letter-spacing: 0.02em;
  //    color: #FFFFFF;
  //
  //    @include phone {
  //      font-size: 18px;
  //      line-height: 22px;
  //    }
  //    p {
  //      font-size: 25px;
  //      line-height: 30px;
  //      letter-spacing: 0.02em;
  //      color: #FFFFFF;
  //      margin: 0;
  //      margin-bottom: 15px;
  //      &:last-child {
  //        margin-bottom: 0;
  //      }
  //
  //      @include phone {
  //        font-size: 18px;
  //        line-height: 22px;
  //      }
  //    }
  //  }
  //
  //}

  @include laptop {
    padding: 30px;
    //min-height: 488px;
  }

  @include phone {
    border: none;
    padding: 0;
  }

  &_about {

    min-height: 300px;
    padding: 38px 66px;

    @include laptop {
      min-height: 270px;
      padding: 55px ;
    }

    @include phone {
      min-height: 180px;
    }
    .feature-text {
      font-size: 25px;
      line-height: 30px;
      letter-spacing: 0.02em;
      color: #FFFFFF;

      @include phone {
        font-size: 18px;
        line-height: 22px;
      }
      p {
        font-size: 25px;
        line-height: 30px;
        letter-spacing: 0.02em;
        color: #FFFFFF;
        margin: 0;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }

        @include phone {
          font-size: 18px;
          line-height: 22px;
        }
      }
    }

  }


}
