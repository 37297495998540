html, body {
	width: 100%;
	height: 100%;
	min-width: 355px;
	overflow: visible;

	//&::-webkit-scrollbar {
	//	display: none;
	//}

	&.hide {
		overflow: hidden;
	}
}

body {
	@include proximaFont;
	font-size: 14px;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	line-height: 1.42;
}

* {
	box-sizing: border-box;
}

.container{
	max-width: 1440px;
	margin: 0 auto;
	padding: 0 166px;

	@include laptop {
		padding-left: 47px;
		padding-right: 47px;
	}

	@include tablets {
		padding-left: 47px;
		padding-right: 47px;
	}
	@include phone {
		padding-left: 14px;
		padding-right: 14px;
	}
}

.wrapper {
	position: relative;
	min-height: 100%;
	overflow: hidden;
}

a {
	text-decoration: none;
}

img {
	display: block;
}

ul {
	padding: 0;
	margin: 0;
}

ul li {
	list-style: none;
}

h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	margin-bottom: 0;
	font-weight: normal;
	line-height: 1.42;
}

p {
	margin: 0;
}
