.offers {
  padding-top: 130px;
  position: relative;
  min-height: 835px;
  background-color: #008C95;

  @include laptop {
    padding-top: 79px;
  }

  @include phone {
    padding-top: 33px;
  }

  .slick-list {
    z-index: 5;
  }

  &:before {
    content: url("../img/brand-edd-second.png");
    position: absolute;
    bottom: -5px;
    left: 0;
    z-index: 1;
  }

  &__container {
    display: flex;
    z-index: 1;

    @include media(1220px) {
      padding-left: 47px;
      padding-right: 47px;
    }

    @include laptop {
      display: block;
    }
  }

  &__left {
    margin-left: -46px;
    width: calc(30% + 46px);
    padding-right: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include laptop {
      margin-left: 0;
      width: 100%;
      display: block;
      margin-bottom: 52px;
    }
    @include phone {
      margin-bottom: 34px;
    }
  }

  &__right {
    width: calc(70% - 145px);
    //width: 70%;
    display: flex;
    align-items: center;
    margin-left: 145px;
    position: relative;
    @include laptop {
      width: 100%;
      margin-left: 0;
    }
    &:before {
      content: url("../img/line.svg");
      position: absolute;
      transform: translateY(-50%);
      top: 42%;
      right: calc(100% - 3px);

      @include laptop {
        position: absolute;
        top: -29px;
        right: 44%;
        transform: scale(0.3) rotate(90deg);
      }
      @include tablets {
        right: 39%;
      }
      @include phone {
        display: none;
      }
    }
  }

  &__bg-pics {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__bg-pic {
    opacity: 0.6;
    mix-blend-mode: multiply;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &:first-child {
      display: block;
    }
  }

  &__arrows {
    position: relative;
    z-index: 1;
    margin-left: 45px;

    button {
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      -webkit-appearance: none;
      padding-bottom: 0;
      padding-top: 0;
      height: 52px;

      @include phone {
        padding: 0;
      }
    }

    .next-slide {
      transform: rotate(180deg);
    }

    .next-slide {
      transform: rotate(180deg);
    }

    .prev-slide {
      margin-right: 7px;
    }

    @include laptop {
      display: none;
    }
    &_mob {

      display: none;

      @include laptop {
        display: block;
        margin-top: 30px;
        margin-left: 0;
        text-align: center;
      }
    }
  }

  &__bg-texts {
    //display: none;
    position: relative;
    z-index: 2;

    @include phone {
      display: none;
    }

  }

  &__bg-text {
    display: none;

    &:first-child {
      display: block;
    }
  }

  &__text-container {
    bottom: 27px;
    position: absolute;
    text-align: center;
    width: 100%;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    opacity: 0.6;

    @include tablets {
      display: none;
    }
  }

  &__heading {
    font-size: 35px;
    line-height: 35px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    position: relative;
    z-index: 1;

    @include laptop {
      margin-bottom: 36px;
    }

    @include phone {
      font-size: 25px;
      line-height: 25px;
      text-align: center;
      margin-bottom: 26px;
    }
  }

  &_about {
    &:before {
      display: none;
    }

    padding-top: 120px;
    min-height: 550px;

    @include laptop {
      padding-bottom: 90px;
    }

    @include phone {
      padding-top: 55px;
      padding-bottom: 55px;
      min-height: 383px;
    }

    .offers {

      &__container {
        @include phone {
          padding: 0;
        }
      }
      &__left {
        width: calc(20% + 46px);

        @include laptop {
          margin-left: 0;
          width: 100%;
          display: block;
          margin-bottom: 95px;
        }
        @include phone {
          margin-bottom: 20px;
          padding-right: 0;
        }
      }
      &__right {
        width: calc(80% - 245px);
        margin-left: 245px;
        &:before {
          content: url("../img/line-long.svg");
          transform: none;
          top: 18%;
          right: calc(100% - 1px);

          @include laptop {
            content: url("../img/line-middle-about.svg");
            position: absolute;
            //top: -29px;
            //right: 44%;

            top: 9px;
            transform: translateY(-100%);
            right: auto;
            left: 94px;

            //transform: scale(0.3) rotate(90deg);
          }
          @include tablets {
            right: 39%;
          }
          @include phone {
            display: none;
          }
        }

        @include laptop {
          width: 100%;
          margin-left: 0;
        }

        @include phone {
          padding-right: 32px;
          padding-left: 32px;
        }
      }

      &__bg-pic {
        opacity: .15;
        mix-blend-mode: normal;
      }
    }
  }
}
