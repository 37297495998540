.footer-contacts {
  &__tel {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;

    color: #FFFFFF;
    display: block;
    margin-bottom: 20px;
  }

  &__mail {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;

    color: #FFFFFF;
    display: block;
    margin-bottom: 20px;
  }
  &__address {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-bottom: 20px;

    a {
      color: #FFFFFF;
      padding-bottom: 0;
      border-bottom: 1px solid rgba(255,255,255,.6);
  }
  }
  &__security {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;

    color: #FFFFFF;
    margin-bottom: 20px;

    a {
      color: #FFFFFF;
      padding-bottom: 0;
      border-bottom: 1px solid rgba(255,255,255,.6);
    }
  }
}
