.filters {
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #003A5A;

  &__item {
    margin-right: 32px;
    min-width: 160px;
    position: relative;

    @include phone {
      margin-right: 0;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__clickable {
    //border-bottom: 1px solid #003A5A;
    //padding: 10px 15px 16px 20px;
    //display: flex;
    //justify-content: space-between;
    //cursor: pointer;
  }

  &__name {
    padding-right: 30px;
  }

  &__dropdown {
    background-color: #fff;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 10px 20px;

    display: none;

    &_wide {
      width: 430px;
    }
  }
  &__drop-item {
    display: block;
    padding: 10px 0;
    color: #003A5A;

    &:hover {
      text-decoration: underline;
    }
  }
}
