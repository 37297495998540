.header {
  background: linear-gradient(182.85deg, #008C95 18.96%, rgba(255, 255, 255, 0) 203.52%), #00C4C4;

  &__container {
    @include nav-tablet {
      padding: 0 50px;
    }
  }
  &__burger {
    margin-left: 0;

    @include nav-tablet {
      margin-left: 40px;
    }
  }

  &__top {
    display: flex;
    padding-top: 19px;
    align-items: center;

    @include nav-tablet {
      padding-bottom: 19px;
    }
  }

  &__bot {
    display: flex;
    align-items: center;

    @include nav-tablet {
      display: none;

      overflow-y: scroll;
      padding-left: 80px;
      padding-right: 10%;
      padding-bottom: 170px;
      padding-top: 24px;
      position: fixed;
      align-items: initial;
      right: 0;
      top: 0;
      background: linear-gradient(217.69deg, #008C95 18.96%, rgba(255, 255, 255, 0) 203.52%), #00C4C4;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
      z-index: 10;
      height: 100vh;
      width: 50vw;

      //flex-wrap: wrap;
      flex-direction: column;

      &.active {
        display: flex;
      }
    }

    @include nav-768-tablet {
      width: 77vw;
      flex-wrap: wrap;
      flex-direction: inherit;
    }

    @include nav-phone {
      width: 100vw;
      overflow-y: scroll;
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 83px;
      flex-wrap: wrap;
      flex-direction: inherit;
    }
  }


  &__logo {
    margin-right: auto;
  }
  &__actions {

  }
  &__call-mail {
    margin-right: 28px;

    @include nav-tablet {
      display: none;
    }



    svg {
      vertical-align: middle;
    }
    span {
      margin-left: 7px;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.02em;
      color: #FFFFFF;
      opacity: 0.6;
      padding-bottom: 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.6);

      @include nav-tablet {
        display: none;
      }
    }
  }

  &__socials {
    margin-right: 51px;
    margin-left: 21px;

    @include nav-768-tablet {
      display: none;
    }

    &_mob {
      display: none;
      @include nav-768-tablet {
        display: block;
        order: 3;
      }

      @include nav-phone {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  &__lang {
    font-size: 18px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.6);

    @include nav-phone {
      display: none;
    }

    &_mob {
      display: none;
      @include nav-phone {
        display: inline-block;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: 0.02em;
        //text-decoration-line: underline;
        color: rgba(255, 255, 255, 0.6);

      }
    }

    a {
      text-transform: uppercase;
      font-size: 18px;
      line-height: 18px;
      letter-spacing: 0.02em;
      //text-decoration-line: underline;
      color: rgba(255, 255, 255, 0.6);
      padding-bottom: 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.6);

      &.active {
        border-bottom: none;
        font-weight: bold;
        text-decoration: none;
        color: white;
        cursor: default;
      }
    }
  }

  &__lang-separator {
    margin-left: 10px;
    margin-right: 10px;
  }

  &__search {
    @include nav-phone {
      width: 100vw;
    }
  }
}
