.socials {

  &__list {
    display: flex;
    margin-bottom: -3px;
  }

  &__item {
    margin-right: 15px;
    width: 24px;

    &:last-child {
      margin-right: 0;
    }
  }
  &__link {
    width: 24px;

    svg {
      width: 24px;
      height:24px;
    }
  }
}
