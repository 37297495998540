.dropdown {


  &__text {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #003A5A;
    width: 100%;

    padding: 4px 11px;

    @include nav-tablet {
      margin-left: 0;
      color: white;
      position: relative;
    }




    &:hover {
      background: #F1F1F1;
      border-radius: 2px;
      font-weight: bold;
      color: #003A5A;

      @include nav-tablet {
        color: white;
        background: rgba(#F1F1F1, 0.15);
        mix-blend-mode: lighten;
      }
    }

  }
}
