.about-mission {
  background-color: #F1F1F1;
  background-image: url("../img/left-about-egg.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;

  @include laptop {
    background-image: none;
  }
  &__container {
    display: flex;
    max-width: 1440px;
    margin: 0 auto;

    @include laptop {
      display: block;
    }
  }
  &__left {
    width: 58%;
    //padding: 65px 40px 80px;
    padding: 54px 40px 80px 167px;

    @include laptop {

      &:before {
        content: '';
        position: absolute;
        //background-image: url("../img/left-about-egg.png");
        background-image: url("../img/egg-mobile-about.png");
        //background-image: url("../img/egg-decor.svg");
        background-repeat: no-repeat;
        background-position: left center;
        background-size: cover;
        width: 150px;
        height: 100%;
        left: 0;
        top: 0;

      }
      position: relative;
      width: 100%;
      padding: 66px 40px 80px;
    }

    @include phone {

      padding: 55px 32px 55px;

      &:before {
        background-position: center;
      }

    }
  }

  &_left-container {
    max-width: 928px;
  }

  &__right {
    width: 42%;
    display: flex;
    position: relative;

    @include laptop {
      width: 100%;
    }

    &:after {
      content: '';
      position: absolute;
      background: linear-gradient(151.08deg, #00BADD 1.83%, rgba(255, 255, 255, 0) 133.53%), #0573A2;;
      top: 0;
      left: 0;
      height: 100%;
      width: 100vw;
    }
  }

  &__right-container {
    //padding: 58px 50px 58px 40px;
    padding: 54px 93px 58px 100px;
    z-index: 1;

    @include laptop {
      padding: 55px 40px 52px;
    }

    @include phone {
      padding: 55px 32px 55px;
    }
  }

  &__heading {
    display: block;
    font-weight: 600;
    font-size: 35px;
    line-height: 35px;
    letter-spacing: 0.02em;
    color: #003A5A;
    margin-bottom: 49px;

    @include laptop {
      margin-bottom: 31px;
    }

    @include phone {
      font-size: 25px;
      line-height: 25px;
      text-align: center;
      margin-bottom: 25px;
    }

    &_white {
      color: #fff;
      margin-bottom: 32px;

      @include phone {
        margin-bottom: 21px;
      }
    }
  }

  &__decor {
    background-color: white;
    width: 32px;
    height: 7px;
    border-radius: 9px;
    margin-bottom: 35px;

    @include phone {
      height: 4px;
      margin-bottom: 24px;
    }
  }

  &__content {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #003A5A;

    @include phone {
      font-size: 14px;
      line-height: 17px;
    }

    p {
      margin: 0;
      margin-bottom: 22px;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.02em;
      color: #003A5A;

      &:last-child {
        margin-bottom: 0;
      }
      @include phone {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 19px;
      }
    }

    &_white {
      color: #fff;

      p {
        color: #fff;
      }
    }

  }
}
