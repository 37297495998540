@include phone {
  .services-acco {
    &__sliding-part {
      display: none;

      &.container {
        padding-top: 0;
        padding-bottom: 0;
      }

      .point__item {
        padding-left: 19px;
      }
    }

    &__action {
      cursor: pointer;
    }

    &__trigger {
      transition: 0.4s;
      transform: rotate(180deg);
      &.active {
        transition: 0.4s;
        transform: none;
      }
    }
  }
}
