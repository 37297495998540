.footer {
  background-color: #008C95;
  //background-color: #019BA6;
  position: relative;
  overflow: hidden;

  //@include phone {
  //  padding-top: 290px;
  //}

  &:after {
    content: url("../img/brand-egg.png");
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;

    @include tablets {
      display: none;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    padding-top: 63px;
    padding-bottom: 63px;

    @include laptop {
     flex-wrap: wrap;
    }

    @include phone {
      padding-left: 60px;
      padding-right: 60px;
    }

  }

  &__heading {
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 20px;
  }

  &__menu-items {

  }

  &__links {
    width: 48%;

    @include laptop {
      width: 65%;
      margin-bottom: 60px;
    }

    @include tablets {
      width: 50%;
    }

    @include phone {
      width: 100%;
    }
  }

  &__logo {
    margin-bottom: 49px;
  }

  &__socials {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 25%;

    @include laptop {
      width: 100%;
    }

    @include phone {
      display: block;
    }

    .socials__item {
      width: 42px;
      z-index: 4;
       svg {
         path {
           fill-opacity: 1!important;
         }
       }
    }

  }
  &__contact {
    padding-right: 74px;

    width: 34%;

    @include laptop {
      width: 30%;
      padding-right: 20px;
    }

    @include tablets {
      width: 50%;
      padding-top: 100px;
    }

    @include phone {
      width: 100%;
      padding-top: 0;
      margin-bottom: 73px;
    }
  }

  &__ss {
    @include tablets {
      width: 50%;
    }

    @include phone {
      .socials__list {
        justify-content: center;
      }
      .footer__heading {
        text-align: center;
      }
      width: 100%;
      margin-bottom: 62px;
    }
  }
}
