.map-links {
  padding-left: 34px;
  width: calc(100% + 68px);
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  @include phone {
    display: none;
  }

  &_small {
    @include phone {
      display: block;
      padding-left: 0;
      width: 100%;
      margin-bottom: 50px;
    }
    display: none;
  }

  &__row {
    //display: flex;
    //justify-content: space-evenly;
    //margin-bottom: 27px;
  }

  &__item {
    cursor: default;
    margin-bottom: 27px;
    margin-right: 68px;
    padding: 6px 20px;
    display: block;
    border: 1px solid white;
    border-radius: 4px;
    position: relative;

    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.02em;
    //text-decoration-line: underline;
    color: #003A5A;
    transition: 0.4s;

    &#all-projects {
      &:hover {
        background: #F1F1F1;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
        cursor: pointer;
      }

    }

    //&:hover {
    //  background: #F1F1F1;
    //  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
    //}

    @include tablets {
      border: none;
    }

    @include phone {
      padding: 0 20px 0 0;
      margin-right: 0;
      margin-bottom: 15px;
      text-align: left;
      &:first-child {
        display: none;
      }
    }

    &_greenish-blue {
      svg {
        circle {
          fill: #00C4C4
        }
        path {
          fill: #00C4C4
        }
      }
    }

    &_dark-blue {
      svg {
        circle {
          fill:  #008C95
        }
        path {
          fill:  #008C95
        }
      }
    }

    &_orange {
      svg {
        circle {
          fill:  #F18700
        }
        path {
          fill:  #F18700
        }
      }
    }

    &_yellow {
      svg {
        circle {
          fill:  #FFC000
        }
        path {
          fill:  #FFC000
        }
      }
    }

    svg {
      vertical-align: middle;
      margin-right: 4px;
    }

    span {
      //text-decoration: underline;
      color: #003A5A;

    }

    &:first-child {
      span {
        text-decoration: none;
        font-weight: 600;

        @include tablets {
          font-weight: 400;
        }
      }
    }

    &:last-child {
      span {
        @include tablets {
          //text-decoration: underline;
        }
      }
    }

    &:first-child {
      background-color: white;

      @include tablets {
        background-color: transparent;
      }

      //&:before {
      //  display: none;
      //}
    }


    //&:before {
    //  position: absolute;
    //  content: url("../img/little-map-pic.svg");
    //  left: 18px;
    //  top: 50%;
    //  transform: translateY(-50%);
    //  width: 19px;
    //  height: 19px;
    //}
  }

  &__text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #003A5A;
    padding:  8px 25px 8px 19px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 4px;

    @include laptop {
      font-size: 18px;
      line-height: 22px;
    }
  }
  &__arr {
    cursor: pointer;
    transition: 0.4s;
    transform: none;
    &.active {
      transform: rotate(-180deg);
    }
  }

  &__dropdown {
    display: none;
    padding: 10px 38px 22px 19px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &__dropdown-text {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #003A5A;

    margin-bottom: 19px;

    @include laptop {
      font-size: 14px;
      line-height: 17px;
    }
  }
}
