.services-sect {
  display: none;
  @include phone {
    display: block;
  }

  &__content {
    padding: 59px 28px 62px;
  }

  &__item {
    &:first-child {
      .tab-item {
        @include phone {
          padding-top: 15px;
        }
      }
    }

    &:last-child {
      .tab-item {
        @include phone {
          padding-bottom: 60px;
        }
      }
    }
  }


  &__text {
    margin-bottom: 42px;
    p {
      font-size: 18px;
      margin: 0;
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__link {
    display: block;
    background: #00C4C4;
  }
}
