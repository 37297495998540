.m-media {
  padding: 54px 0;
  position: relative;
  background-color: #F1F1F1;
  overflow: hidden;

 @include tablets {
   .card_photo {
     &:nth-child(1n+3) {
       display: none;
     }
   }
 }

  @include phone {
    padding: 54px 0 0 0;

    .card_photo {
      &:nth-child(1n+3) {
        display: block;
      }
    }
    .m-media__card-item {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }


  &:after {
    content: url("../img/egg-media.png");
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    pointer-events: none;

    @include phone {
      content: url("../img/egg-media-phone.png");
    }
  }

  h2 {
    font-weight: 600;
    font-size: 35px;
    letter-spacing: 0.02em;
    color: #003A5A;
    margin-bottom: 52px;

    @include phone {
      font-size: 25px;
      margin-bottom: 34px;
      text-align: center;
    }
  }

  &__link {
    margin-bottom: 35px;
  }
  &__cards-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -30px;
    width: calc(100% + 30px);

    @include phone {
      margin-left: 0;
      width: 100%;
      margin-bottom: 32px;
    }

  }

  &__card-item {
    width: calc(50% - 30px);
    margin-left: 30px;
    margin-bottom: 40px;

    &_3 {
      width: calc(33.33333% - 30px);

      @include tablets {
        width: calc(50% - 30px);
      }

      @include phone {
        margin-bottom: 35px !important;
      }
    }
    @include phone {
      width: 100%;
      margin-left: 0;
      margin-bottom: 8px;
    }
  }

}
