.news-filters {
  background-color: #F1F1F1;
  background-image: url("../img/left-about-egg.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: auto 100%;
  letter-spacing: 0.02em;
  color: #003A5A;
  padding-top: 50px;
  padding-bottom: 60px;

  @include phone {
    background-image: url("../img/egg-media-page-1.png");
  }

  &__h2 {
    font-weight: 600;
    font-size: 35px;
    line-height: 35px;
    margin-right: 40px;

    @include phone {
      font-size: 25px;
      line-height: 25px;
      text-align: center;
      margin-right: 0;
    }
  }

  &__flex, &__news-list {
    max-width: 892px;
  }

  &__flex {
    display: flex;
    align-items: center;
    margin-bottom: 63px;

    @include media(900px) {
      display: block;

      .news-filters__h2 {
        margin-bottom: 24px;
      }
    }

    @include phone {
      margin-bottom: 35px;
    }
  }

  &__filters {
    display: flex;

    @include phone {
      display: block;
    }
  }

  &__news-item {
    margin-bottom: 35px;
  }

  &__btn {
    margin: 0 auto;
    //display: block;
    //
    //&.hidden {
    //  display: none;
    //}
  }

}
