.nav {
  &__trigger {
    display: none;

    @include nav-tablet {
      display: inline-block;
    }
  }
  &__link {
    @include proximaFont;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    padding-right: 5px;
    position: relative;

    @include nav-tablet {
      font-size: 18px;
      line-height: 22px;

      margin-bottom: 12px;
      text-transform: unset;

      display: flex;
      justify-content: space-between;
    }

    &:before {
      content: '';
      position: absolute;
      height: 3px;
      left: 0;
      top: 30px;
      opacity: 0;
      transition: 0.3s;

      background: #FFFFFF;

      @include nav-tablet {
        display: none;
      }
    }

    &:hover {
      &:before {
        opacity: 1;

        background: #FFFFFF;

        @include nav-tablet {
          display: none;
        }
      }
    }
  }
  &__list {
    display: flex;
    padding-top: 23px;
    padding-bottom: 23px;

    @include nav-tablet {
      order: 2;
      display: block;
      padding-top: 63px;
      padding-bottom: 81px;
      height: auto;
      width: 100%;
    }

    @include nav-phone {
      width: 100vw;
    }

  }

  &__item {
    position: relative;
    margin-right: 34px;
    flex-shrink: 0;

   @include nav-tablet {
     margin-bottom: 23px;
     margin-right: 0;

     &:last-child {
       margin-bottom: 0;
     }
   }

    &_has-dropdown {

      @include media-min(1420px) {
        &:hover {
          .nav__dropdown {
            z-index: 4;
            opacity: 1;
            top: calc(100% + 11px);
          }

          .nav__link:after {
            transform: rotate(180deg);
            top: 0;
          }

          .nav__link:before {
            opacity: 1;
          }
        }
      }
    }
  }

  &__dropdown {
    transition: opacity 0.5s ease;
    opacity: 0;
    width: 100%;
    position: absolute;
    left: 0;
    top: -9999px;
    background-color: white;
    min-width: 328px;

    padding-top: 20px;
    padding-bottom: 27px;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 2px;

    @include nav-tablet {
      box-shadow: none;
      padding-top: 0;

      border-bottom: 1px solid rgba(white, 0.5);
    }


    &:after {
      content: "";
      position: absolute;
      top: 0;
      margin-top: -15px;
      left: 29px;
      border: solid 8px transparent;
      border-right-color: #fff;
      z-index: 1;
      transform: rotate(90deg);

      @include nav-tablet {
        display: none;
      }
    }

    &:before {
      content:'';
      display: block;
      height: 10px;
      width: 100%;
      position: absolute;
      bottom: 100%;
      left: 0;

      @include nav-tablet {
        display: none;
      }
    }
    @include nav-tablet {
      //display: none;
      opacity: 1;
      position: relative;
      top: 0;
      background-color: transparent;
    }

  }
  &__dropdown-item {
    display: flex;
    //padding: 13px 11px 28px 24px;
    padding-left: 13px;
    padding-right: 12px;
    transition: 0.3s;
    margin-bottom: 10px;

    @include nav-tablet {
      padding-left: 0;
      padding-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
