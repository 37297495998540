.open-news {
  .info:after {
    display: none !important;
  }

  .info__link {

    margin-left: 7px;

    @include laptop {
      font-size: 16px !important;
      line-height: 19px !important;
    }


    @include phone {
      font-size: 14px !important;
      line-height: normal !important;
    }
  }

  .info__docs-list a {
    font-size: 16px;
    line-height: 27px;

    @include phone {
      font-size: 14px;
      line-height: 17px;
    }
  }

  &__container {
    display: flex;
    max-width: 1440px;
    margin: 0 auto;

    @include laptop {
      display: block;
    }
  }
  &__left {
    width: 65%;
    padding: 54px 40px 80px 167px;

    @include laptop {
      width: 100%;
      padding: 54px 40px 0;
    }

    @include phone {
      padding: 55px 32px 37px;
    }
  }

  &_left-container {
    max-width: 928px;
  }

  &__right {
    width: 35%;

    @include laptop {
      width: 100%;
    }
  }

  &__right-container {
    padding: 54px 31% 58px 36px;
    background-color: #F1F1F1;
    z-index: 1;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      background: #F1F1F1;
      top: 0;
      left: 0;
      height: 100%;
      width: 100vw;
      z-index: -1;
    }

    @include laptop {
      padding: 55px 40px 52px;
    }

    @include phone {
      padding: 55px 32px 55px;
    }
  }

  &__heading {
    display: block;
    font-weight: 600;
    font-size: 35px;
    line-height: 35px;
    letter-spacing: 0.02em;
    color: #003A5A;
    margin-bottom: 38px;

    @include laptop {
      margin-bottom: 7px;
    }

    @include phone {
      font-size: 25px;
      line-height: 25px;
      text-align: center;
      margin-bottom: 25px;
    }

    &_white {
      color: #fff;
      margin-bottom: 32px;

      @include phone {
        margin-bottom: 21px;
      }
    }
  }

  &__h3 {
    font-weight: 600;
    font-size: 22px;
    letter-spacing: 0.02em;
    color: #003A5A;
    margin-bottom: 15px;
    margin-top: 29px;
    line-height: 22px;

    @include phone {
      font-size: 18px;
      line-height: 19px;
      margin-bottom: 14px;
      margin-top: 32px;
    }
  }

  &__text {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #003A5A;

    a {
      color: #003A5A;
    }

    @include phone {
      font-size: 14px;
      line-height: 17px;
    }

    p {
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.02em;
      color: #003A5A;
      margin: 0;
      padding: 0;
      @include phone {
        font-size: 14px;
        line-height: 17px;
      }
    }
  }

  &__form {

    @include laptop {
      display: flex;
      width: 100%;

      @include phone {
        flex-direction: column;
        align-items: center;
      }

      .open-news__input {
        -webkit-appearance: none;
        border-radius: 0;
        width: 50%;
        max-width: 350px;
        padding-right: 34px;

        @include media(1245px) {
          width: calc(50% - 28px);
          max-width: none;
          margin-right: 28px;
          margin-bottom: 8px;
        }

        @include phone {
          width: 100% !important;
          margin-right: 0 !important;
          margin-bottom: 41px !important;
        }

      }
    }
  }

  &__input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #003A5A;
    padding: 10px 30px 10px 10px;
    position: relative;
    width: 100%;
    font-size: 18px;
    outline: none;
    margin-bottom: 4px;

    @include placeholder {
      color: #003A5A;
      font-size: 18px;
    }
  }
  &__bottom {
    margin-top: 51px;

    @include phone {
      margin-top: 59px;
    }

    .open-news__heading {
      margin-bottom: 24px;

      @include laptop {
        margin-bottom: 36px;
      }
    }
  }

  .a-serv-content__slider {
    @include tablets {
      height: 468px !important;
    }

    @include phone {
      height: auto!important;
      margin-bottom: 0;
    }
  }

  &__bot-left, &__bot-right {
    @include laptop {
      display: flex;

      .open-news__person {
        width: 50%;
        &:first-child {
          width: calc(50% - 27px);
          margin-right: 27px;
        }
      }
    }

    @include phone {
      display: block;
      .open-news__person {
        width: 100%;
        &:first-child {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }

}
