.services-page {
  display: flex;
  width: 100%;

  @include laptop {
    flex-wrap: wrap;
  }
  &__1 {
    width: 37%;
    opacity: 0.2;
    background-size: cover;
    background-position: center;

    @include media(1244px) {
      width: 45%;
    }

    @include laptop {
      width: 50%;
    }
  }
  &__2 {
    width: 26%;
    background: #F1F1F1;
    box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.2);

    padding: 48px 52px;

    @include media(1244px) {
      width: 30%;
    }

    @include laptop {
      width: 50%;
      min-height: 712px;
    }
  }
  &__3 {
    width: 37%;
    background-size: cover;
    background-position: center;

    @include media(1244px) {
      width: 25%;
    }

    @include laptop {
      width: 100%;
      height: 478px;
      position: relative;
      z-index: 1;
    }
  }

  &__text {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #003A5A;
    margin-bottom: 47px;

    p {
      margin: 0;
      margin-bottom: 23px;
    }
  }
}


#hidden-fix {
  position: absolute;
  top: -9999999px;
}
