.project-about {
  position: relative;
  padding: 99px 0 55px 51px;
  font-size: 18px;
  letter-spacing: 0.02em;
  color: #003A5A;

  @include phone {
    font-size: 14px;
  }


  &:after {
    content: '';
    position: absolute;
    background: #F1F1F1;
    top: 0;
    left: 0;
    height: 100%;
    width: 2000px;
    z-index: -1;

    @include laptop {
      width: 100%;
    }

    @include phone {
      left: -50px;
      width: calc(100% + 100px);
    }
  }

  &__h2 {
    font-weight: 600;
    font-size: 22px;
    letter-spacing: 0.02em;
    color: #003A5A;

    @include phone {
      font-size: 18px;
      text-align: center;
    }
  }

  &__industry {
    border-bottom: 1px solid rgba(#003A5A, 0.2);
    display: flex;
    align-items: center;
    padding-bottom: 35px;
   @include phone {
     padding-bottom: 23px;
   }

  }
  &__pic {
    width: 41px;
    margin-right: 20px;

    img {
      max-width: 100%;
    }
  }
  &__capacity {
    padding: 35px 0;
    border-bottom: 1px solid rgba(#003A5A, 0.2);

    @include phone {
      padding: 22px 0;
    }
  }
  &__list {
    padding: 35px 0;

    @include phone {
      padding: 22px 0;
    }

    &:first-child {
      border-bottom: 1px solid rgba(#003A5A, 0.2);

      @include laptop {
        border-bottom: none;
      }

      @include phone {
        border-bottom: 1px solid rgba(#003A5A, 0.2);
      }
    }

    @include laptop {
      width: 50%;
    }
    @include phone {
      width: 100%;
    }

    &:last-child {
      @include laptop {
        padding-left: 34px;
      }

      @include phone {
        padding-left: 0;
      }
    }
  }
  &__lists {

    @include laptop {
      display: flex;
    }

    @include phone {
      display: block;
    }
    ul, li {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.02em;
      color: #003A5A;

      @include phone {
        font-size: 14px;
        line-height: 17px;
      }
    }

    ul {
      margin-top: 28px;
    }

    li {
      position: relative;
      padding-left: 37px;
      margin-bottom: 25px;

      @include phone {
        margin-bottom: 16px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        content: url("../img/list-arr-a-serv.svg");
        position: absolute;
        left: 0;
        top: 4px;
      }
    }
  }

  &__highlight {
    font-weight: 600;
    font-size: 22px;
    letter-spacing: 0.02em;
    color: #003A5A;

    @include phone {
      font-size: 18px;
    }
  }
  &__text {
    padding-top: 25px;

    @include phone {
      padding-top: 16px;
    }
  }
}
