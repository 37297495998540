.image-bg {
  position: relative;
  height: 700px;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @include laptop {
    height: 758px;
  }

  @include tablets {
    height: 450px;
  }

  &_open-news {
    height: 500px;

    .image-bg__container {
      position: relative;
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 58, 90, 0)), to(#003a5a));
      background: linear-gradient(180deg, rgba(0, 58, 90, 0) 0, #003a5a 100%);
    }
    .image-bg__container {
      margin-top: 128px;

      @include phone {
        margin-top: 54px;
      }
    }

    @include tablets {
      height: 761px;
    }

    @include tablets {
      height: 450px;
    }
  }

  &_procurement {

  }

  &_fade {

  }

  &__time {
    font-size: 18px;
    line-height: normal;
    letter-spacing: 0.02em;
    color: #FFFFFF;

    @include phone {
      font-size: 14px;
    }
  }

  &__text {
    font-weight: 300;
    font-size: 53px;
    line-height: 53px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-top: 180px;
    margin-bottom: 43px;
    max-width: 610px;

    @include laptop {
      margin-top: 131px;
    }

    @include tablets {
      font-size: 38px;
      line-height: 38px;
      margin-top: 82px;
      max-width: 517px;
    }

    &_open-news {
      max-width: 710px;
      font-size: 35px;
      line-height: 36px;
      margin-top: 3px !important;

      @include tablets {
        font-size: 35px;
        max-width: 667px;
      }

      @include phone {
        font-size: 29px;
        line-height: 29px;
        margin-top: 12px !important;
        max-width: 96%;
      }
    }
  }
}
