.nav-info {
  position: relative;
  padding: 55px 0 55px 51px;

  &:before {
    content: '';
    position: absolute;
    background: #008C95 url("../img/info-about-back.png") no-repeat center;
    background-size: cover;
    top: 0;
    left: 0;
    height: 100%;
    width: 1200px;

    @include laptop {
      width: 100%;
    }

    @include phone {
      left: -50px;
      width: calc(100% + 100px);
    }
  }
  &__container {
    position: relative;
    z-index: 1;
  }

  &__item, &__active {
    font-size: 22px;
    line-height: 99.3%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-bottom: 35px;

    @include phone {
      font-size: 18px;
    }

    &:first-child {
      @include phone {
        //display: none;
      }
    }

    a {
      color: #FFFFFF;
      display: inline-block;
      transition: 0.4s;

      &:hover {
        transform: scale(1.05) translateX(5px);
      }
    }

    &.active {
      font-weight: 600;
      position: relative;
      padding-left: 25px;

      &:before {
        content: '';
        background: url(../img/left-deco-el.svg) no-repeat center;
        background-size: contain;

        width: 14px;
        height: 14px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__active {
    display: none;
    @include phone {
      display: block;
    }
  }

  &__list {


    @include tablets {
      column-count: 2;
      column-gap: 45px;
      margin-bottom: 35px;

    }

    @include phone {
      column-count: 1;
      column-gap: 0;
      display: none;

      //.nav-info__item {
      //  &:first-child {
      //    font-weight: normal;
      //    position: static;
      //    padding-left: 0;
      //    &:before {
      //      display: none;
      //    }
      //  }
      //}
    }
  }

  &__h1 {
    position: relative;
    @include phone {
      &:before {
        content: '';
        background: url(../img/up-arr.svg) no-repeat center;
        background-size: contain;

        width: 14px;
        height: 14px;
        position: absolute;
        top: 30%;
        transform: rotate(180deg);
        right: 0;
        transition: 0.3s ease-out;
      }

      &.active {
        &:before {
          transform: rotate(0);
        }
      }
    }
  }
}
