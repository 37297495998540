.media-slider {
  height: 100%;
  background-color: $spindrift;

  video {
    position: absolute;
    top: 0;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translateX(-50%);
    background-size: cover;
    -webkit-transition: 1s opacity;
    transition: 1s opacity;

    //top: 50%;
    //left: 50%;
    //min-width: 100%;
    //min-height: 100%;
    //width: auto;
    //height: auto;
    ////z-index: 1;
    //transform: translateX(-50%) translateY(-50%);
    //background-size: cover;
    //transition: 1s opacity;

    @include phone {
      //height: 289px;
      //overflow: hidden;
      //transform: none;
      //left: 0;
      //width: auto;
      //top: 0;
      //min-height: auto;
      //min-width: auto;


      transform: none;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 100%;
      transition: all .25s linear;
    }
  }

  @include laptop {
    height: 768px;
  }

  @include tablets {
    height: 683px;
  }

  @include phone {
    height: auto;
  }


  &__container {
    height: 100%;
    position: relative;
    overflow: hidden;

    @include phone {
      overflow: visible;
    }
  }

  &__controls {
    &_mob {
      display: none;
    }

    @include phone {
      //display: none;

      //position: absolute;
      //display: flex;
      //justify-content: center;
      ////bottom: -198px;
      //width: 100%;
      //top: 159%;



      width: 100%;
      position: absolute;
      //bottom: 48px;
      bottom: -280px;
      display: flex;
      justify-content: center;
      z-index: 10;


      //
      &_mob {
        //height: 22px;
        //padding-top: 61px;
        //display: flex;
        //justify-content: center;
         display: none;
      }
    }



    .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after,
    .swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
      content: '';
    }

    .swiper-button-next, .swiper-button-prev {
      outline: none;
      width: 67px;
      //height: 67px;
      top: auto;
      bottom: 40px;

      @include phone {
        position: static;

      }
    }

    .swiper-button-next {
      margin-right: 41px;
      transform: rotate(180deg);

      @include phone {
        margin-right: 0;
      }
    }

    .swiper-button-prev {
      right: 125px;
      left: auto;

      @include phone {
        margin-right: 20px;
      }
    }
  }

}
