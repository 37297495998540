.formats-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  &__descr {
    //display: none;
  }

  &__item {
    font-weight: 300;
    font-size: 35px;
    line-height: 35px;
    letter-spacing: 0.02em;
    color: rgba(#FFFFFF, 0.8);
    cursor: pointer;
    padding-bottom: 38px;
    margin-bottom: 38px;

    border-bottom: 1px solid #FFFFFF;

    @include tablets {
      font-size: 26px;
    }

    &:last-child {
      border: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    &.active {
      font-weight: 600;
      color: white;
      pointer-events: none;
      user-select: none;
      cursor: default;
      position: relative;


      &:before {
        content: url("../img/left-deco-el.svg");
        width: 16px;
        height: 18px;
        position: absolute;
        top: 0;
        right: calc(100% + 16px);
      }
    }
  }

  &__top {
    height: 50%;
    background: rgba(#003A5A, 0.3);
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    overflow: hidden;
    padding: 55px 69px 50px 66px;

    @include tablets {
      padding: 45px 30px 45px 45px;
    }

  }

  &__bot {
    position: relative;
    height: 50%;
    padding: 49px 43px 40px 66px;

    @include tablets {
      padding: 45px 30px 45px 45px;
    }

    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(164.95deg, #008c95, rgba(255, 255, 255, 0)), #00C4C4;
      opacity: 0.9;
    }
  }

  &__descr {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  &__text {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-bottom: 30px;

    @include tablets {
      font-size: 16px;
      line-height: 19px;
    }
  }
  &__btn {
    align-self: center;
  }
}
