.map {
  width: 100%;
  background-color: #F1F1F1;
  @include proximaFont;
  padding: 75px 0 67px;

  svg {
    //path {
    //  &.active {
    //
    //    fill: #00C4C4;
    //    fill-opacity: 0.5;
    //  }
    //}
  }

  &__heading {
    margin-bottom: 54px;
    text-align: center;
    font-weight: 300;
    font-size: 45px;
    line-height: 45px;
    letter-spacing: 0.02em;
    color: #003A5A;

    @include phone {
      font-size: 35px;
      line-height: 99.3%;
    }
  }

  &__pic {
    position: relative;

  }

  &__pin {
    //&:hover {
    //  .svg-map {
    //    path {
    //      &.active {
    //
    //        fill: darken(#00C4C4, 7%);
    //
    //      }
    //
    //    }
    //  }
    //}
  }
}

.pin__pic {
  position: absolute;

  &--1 {
    top: 33%;
    left: 43%;
  }
}

.svg-map {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    min-height: 500px;
  }

  @include tablets {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      min-height: 420px;
    }
  }

  @include phone {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      min-height: 300px;
    }
  }
  path {
    //stroke:#fff!important;
    transition:.2s;
    &.hovered {
      fill: rgb(0, 255, 255)
    }
    &.active {
      &:hover {
        fill: rgb(0, 255, 255)
      }
    }

  }
}
