.info-quote {
  position: relative;
  padding: 70px 40px 35px;
  margin-bottom: 35px;
  background: #F1F1F1;
  border-radius: 4px;

  @include phone {
    padding: 52px 28px 35px;
  }

  &:before {
    width: 16%;
    height: 15%;
    left: 20px;
    top: 21px;
    content: '';
    position: absolute;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../img/quote-pseudo.svg");

    @include phone {
      left: 26px;
      top: -8px;
    }
  }
  &__container {

  }

  &__text {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #003A5A;
    margin-bottom: 35px;

    @include phone {
      font-size: 14px;
      line-height: normal;
      margin-bottom: 20px;
    }

    p {
      font-size: 18px;
      color: #003A5A;
      margin-bottom: 23px;

      @include phone {
        font-size: 14px;
        line-height: normal;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__author {
    position: relative;
    font-size: 22px;
    line-height: 27px;
    letter-spacing: 0.02em;
    color: #003A5A;
    padding-top: 40px;

    @include phone {
      font-size: 18px;
      line-height: normal;
      padding-top: 23px;
    }


    &:before {
      content: '';
      position: absolute;
      background: linear-gradient(179.78deg, #00BADD 1.83%, rgba(255, 255, 255, 0) 133.53%), #0573A2;;
      height: 4px;
      width: 100%;
      top: 0;
      left: 0;
    }

    p {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

  }

}
