.m-news {
  background: linear-gradient(166.48deg, #00BADD 1.83%, rgba(255, 255, 255, 0) 133.53%), #0573A2;
  padding: 60px 0;

  &_news {
    padding-bottom: 8px;
  }

  @include phone {
    padding: 52px 0;
  }

  &__cards-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -30px;
    width: calc(100% + 30px);

    @include phone {
      margin-left: 0;
      width: 100%;
    }

    @include tablets {
      .card {
        &:nth-child(1n+3) {
          display: none;
        }
      }
    }

    @include phone {
      .card {
        &:nth-child(1n+3) {
          display: block;
        }
      }
    }

  }

  &__card-item {
    width: calc(33.3333333% - 30px);
    margin-left: 30px;
    margin-bottom: 40px;

    &_4 {
      width: calc(25% - 30px);

    }

    @include tablets {
      width: calc(50% - 30px);
    }


    @include phone {
      width: 100%;
      margin-left: 0;
      margin-bottom: 55px;

        &:last-child {
          margin-bottom: 0;
        }

    }
  }
  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 55px;

    @include phone {
      display: block;
      text-align: center;
      margin-bottom: 35px;
    }
  }
  h2 {
    font-weight: 600;
    font-size: 35px;
    line-height: 37px;
    letter-spacing: 0.02em;
    color: #FFFFFF;

    @include phone {
      font-size: 25px;
      margin-bottom: 34px;
      text-align: center;
      line-height: 27px;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 35px;
    line-height: 37px;
    letter-spacing: 0.02em;
    color: #FFFFFF;

    @include phone {
      font-size: 25px;
      margin-bottom: 34px;
      text-align: center;
      line-height: 27px;
    }
  }

  &__all-link {

  }
}
