.serv-h1 {
  font-weight: 300;
  font-size: 53px;
  line-height: 53px;
  letter-spacing: 0.02em;
  color: #FFFFFF;

  @include tablets {
    font-size: 38px;
  }
}
