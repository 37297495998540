.procurement-heading {
  position: relative;
  margin-bottom: 55px;
  margin-top: 55px;


  @include phone {
    margin-bottom: 48px;
    margin-top: 48px;
  }

  &_pic {
    background-color: #000;
    min-height: 500px;
    margin-top: 0;

    @include laptop {
      min-height: 758px;
    }

    @include tablets {
      min-height: 450px;
    }

    @include phone {
      margin-top: 0;
    }


    .procurement-heading {
      &__heading {
        color: #fff;
      }

      &__text {
        color: #fff;
      }

      &__container {
        padding-top: 128px;
        padding-right: 338px;

        @include laptop {
          padding-top: 150px;
          padding-left: 47px;
          padding-right: 47px;

        }

        @include tablets {
          padding-top: 82px;
          padding-left: 47px;
          padding-right: 47px;
        }

        @include phone {
          padding-top: 60px;
          padding-left: 14px;
          padding-right: 14px;
        }
      }
    }
  }

  &__bg-img {
    position: absolute;
    opacity: 0.6;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }

  &__container {
    position: relative;
    z-index: 1;
    line-height: normal;
  }

  &__heading {
    font-weight: 300;
    font-size: 53px;
    line-height: 99.3%;
    letter-spacing: 0.02em;
    color: #003A5A;
    margin-bottom: 24px;

    @include laptop {
      margin-bottom: 35px;
    }

    @include tablets {
      margin-bottom: 25px;
      font-size: 38px;
    }

    @include phone {
      margin-bottom: 15px;
      font-size: 35px;
    }
  }

  &__text {
    font-size: 18px;
    letter-spacing: 0.02em;
    color: #003A5A;
    max-width: 736px;

    @include phone {
      font-size: 14px;
    }
  }
}
