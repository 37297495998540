.tabs-content {
  position: relative;

  &__tabs {
    height: 2000px;
  }

  @include phone {
    display: none;
  }
}

.tab-scenes {
  min-height: 712px;
  display: flex;
  align-items: stretch;

  &__item {
    position: absolute;
    min-width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;

    &.active {
      transition: 0.5s;
      position: relative;
      display: flex;
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.tabs {
  width: 37%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;

  &_static {
    width: 100%;
    position: relative;
    z-index: 2;
    justify-content: initial;

    @include laptop {
      width: auto !important;
    }
    @include tablets {
      width: auto !important;
    }
    @include phone {
      width: auto !important;
    }

    .tabs {
      &__list {
        width: 100%;
        background: none;
        box-shadow: none;
        padding: 0;
        margin: 0;
        z-index: 1;

        @include media(1244px) {
          flex-wrap: wrap;
          display: flex;
        }
      }
    }

    .tab-item {

      @include media(1244px) {
        width: 50%;
      }

      @include tablets {
        padding: 0;
        padding-right: 15px;
      }

      @include phone {
        width: 100%;
        padding-right: 0;
      }

      &__link {
        @include phone {
          padding-right: 0;
        }
      }
      &__pic {
        background: none;
        width: 66px;
        padding: 10px 0;
        @include media(1244px) {
          padding: 20px 0;
        }

        @include tablets {
          display: block;
        }

        @include phone {
          padding: 15px 0;
          width: 57px;
        }

        &:after {
          display: none;
        }

      }
    }
  }

  @include media(1244px) {
    width: 45%;
  }

  @include laptop {
    width: 50%;
    height: calc(100% - 478px)
  }

  @include tablets {
    height: 100%;
  }

  &__list {
    //background: linear-gradient(136.06deg, #008C95 3.11%, rgba(255, 255, 255, 0) 137.01%), #00C4C4;
    background: linear-gradient(135deg, #008C95 3.11%, rgba(255, 255, 255, 0) 137.01%), #00C4C4;
    box-shadow: 0px 0px 100px rgba(0, 58, 90, 0.2);
    //width: 70%;
    width: 90%;
    padding: 48px 0;
    opacity: 0.9;

    @include laptop {
      width: 100%;
    }

  }
}

.tab-item {
  padding-right: 15px;

  &:last-child {
    .tab-item__pic {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 2000px;
        background-color: rgba(0, 196, 196, .3);
      }
    }
  }

  @include tablets {
    padding: 17px 35px 17px 45px;
  }

  @include phone {
    padding: 15px 22px;
  }
  &__container {
    display: flex;

    @include tablets {
      justify-content: space-between;
    }

    @include phone {
      align-items: center;
    }
  }
  &__pic {
    //flex: 0;
    width: 96px;
    padding: 10px 20px;
    //background: #00C4C4;
    mix-blend-mode: screen;
    //opacity: 0.3;
    background: rgba(#00C4C4, 0.3);
    margin-right: 19px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include tablets {
      display: none;
    }

    @include phone {
      display: flex;
      background-color: transparent;
      width: auto;
      padding: 0;
    }
  }

  &__image {
    margin: 0;
    position: relative;

    @include phone {
      margin-right: 11px;
    }

    & > img {
      animation: rotate 1.4s linear infinite;
    }

  }

  &__inner-pic {
    position: absolute;
    top: 13px;
    left: 14px;

    &_3 {
      top: 17px;
      left: 11px;
    }
  }

  &__link {
    align-self: center;
    //max-width: 209px;
    max-width: 309px;
    font-weight: 300;
    font-size: 22px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    //padding: 2px 0;

    &.active {
      font-weight: 600;
    }

    @include tablets {
      flex-grow: 1;
      max-width: none;
      padding-right: 28px;
      font-size: 20px;
    }
  }

  &.active {
    position: relative;
    background-color: #00C4C4;

    .tab-item__link {
      font-weight: 600;
    }

    &:before {
      content: '';
      position: absolute;
      right: 100%;
      top: 0;
      background-color: #00C4C4;
      mix-blend-mode: normal;
      opacity: 0.3;
      height: 100%;
      width: 100vw;

    }

    &:after {
      content: '';
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
      display: inline-block;
      width: 0;
      height: 0;
      border-top: solid 11px transparent;
      border-bottom: solid 12px transparent;
      border-right: solid 3px transparent;
      border-left: solid 13px;
      border-left-color: #00C4C4;
    }
  }
}
