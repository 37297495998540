.procurement {
  position: relative;

  &:before {
    content: url("../img/egg-proc.png");
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    pointer-events: none;

    @include laptop {
      display: none;
    }
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    margin-left: -32px;
    width: calc(100% + 64px);

    @include tablets {
      display: block;
      margin-left: 0;
      width: 100%;
    }

    @include phone {
      margin-left: -14px;
      width: calc(100% + 28px);
    }
  }


}
