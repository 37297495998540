.select2 {
  max-width: 100%;

  @include phone {
    width: 100%;
  }
}

.select2-container {
  // clickable area
  .select2-selection {
    border: none;
    border-bottom: 1px solid #003A5A;
    padding: 10px 15px 16px 3px;
    background: transparent;
    color: #003A5A;
    border-radius: 0px;
    outline: none;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    height: auto;
  }

  .select2-search__field {
    display: none;
  }

  .select2-dropdown {
    //max-width: 500px;
    z-index: 0;
    border: 1px solid #e7e7e7;
    border-top: none;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .select2-selection--single {
    .select2-selection__rendered {
      padding-left: 5px;
      color: #003A5A;
      font-size: 18px;
    }
  }

  //options in a dropdown list
  .select2-results {
    overflow-x: auto;
    max-height: 208px;

    &__options {
      //max-height: 208px !important;
      max-width: none !important;
    }

    &__option {
      font-size: 18px;
      padding: 10px 11px;
      //white-space: nowrap;
      color: #003A5A;

      &--highlighted {
        background-color: rgba(196, 196, 196, 0.3) !important;
        color: #003A5A !important;
      }
    }
  }

  .select2-selection--single .select2-selection__arrow {
    width: 17px;
    height: 11px;
    top: 20px;
    right: 12px;
    //b {
    //  border-color: #000 transparent transparent transparent;
    //}
  }

  &--default {
    svg {
      transition: 0.4s;
    }
    &.select2-container--open .select2-selection--single .select2-selection__arrow {
      svg {
        transform: rotate(180deg);
        transition: 0.4s;
      }
    }
    //&.select2-container--open .select2-selection--single .select2-selection__arrow b {
    //  border-color: transparent transparent #000 transparent;
    //}
  }

  .select2-selection__placeholder {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #003A5A !important;
  }

}

.select2-results {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 3px;
    background-color: rgba(255, 255, 255, 0.2);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 0px solid rgba(255, 255, 255, 0.2); /* should match background, can't be transparent */
    background-color: grey;
  }
}

//.select2-container--opaque.select2-container--open:not(.select2) .select2-dropdown input
