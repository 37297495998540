.slider2 {
  position: relative;
  margin-bottom: 137px;
  margin-top: 55px;

  @include laptop {
    margin-bottom: 57px;
  }

  @include phone {
    margin-bottom: 137px;
  }

  &__slide {
    display: flex;
    margin-left: -30px;
    padding-right: 30px;
    width: calc(100% + 60px);

    .card {
      width: 50%;
      margin-left: 30px;

      @include phone {
        width: 100%;
        margin-left: 0;
        padding-right: 0;
      }
    }
    @include phone {
      display: block;
      margin-left: 0;
    }

  }
  &__controls {
    position: absolute;
    display: flex;
    //top: -90px;
    top: -5px;
    right: 0;

    @include phone {
      bottom: -53px;
      top: auto;
      @include centered('x');
      right: auto;
    }

    .swiper-button-next-proj, .swiper-button-prev-proj {
      width: 68px;
      height: 52px;
      cursor: pointer;
      outline: none;
      svg {
        rect, path {
          stroke: #3EAFCE;
        }
      }
    }

    .swiper-button-next-proj {
      transform: rotate(180deg);
      margin-left: 8px;
    }
  }
}
