.left-deco-block {

  &__item {
    border-left: 2px solid #00C4C4;
    padding: 40px 20px;
    position: relative;
    font-size: 18px;
    @include phone {
      font-size: 14px;
    }

    &:first-child {
      padding-top: 0;

      &:before {
        width: 11px;
        height: 11px;
        background-image: none;
        background-color: #00C4C4;
        border-radius: 50%;
        left: -6px;
      }
    }

    &:before {
      content: '';
      position: absolute;
      background-image: url("../img/left-deco-pic.svg");
      background-repeat: no-repeat;
      background-size: cover;
      width: 31px;
      height: 16px;
      left: 0;
      top: -9px;
    }

    &:last-child {
      border-color: transparent;
    }
  }

  &__name {
    position: absolute;
    top: -16px;
    left: 0;
    padding-left: 50px;
    font-size: 22px;
    letter-spacing: 0.02em;

    @include phone {
      font-size: 18px;
    }

  }
}
