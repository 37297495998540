.a-serv-content {

  &__container {
    display: flex;
    justify-content: flex-end;
    max-width: 1440px;
    margin: 0 auto;

    @include media(1244px) {
      display: block;
    }
  }
  &__left {
    width: 65%;
    padding: 58px 33px 65px 166px;

    @include media(1244px) {
      width: 100%;
    }

    @include laptop {
      padding: 90px 40px 0;
    }

    @include phone {
      padding: 46px 26px 0;
    }
  }

  &__left-container {
    max-width: 928px;
  }
  &__info {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #003A5A;

    @include phone {
      font-size: 14px;
      line-height: 17px;
    }

    h2 {
      font-weight: 600;
      font-size: 35px;
      line-height: normal;
      letter-spacing: 0.02em;
      color: #003A5A;
      margin-bottom: 32px;

      @include phone {
        font-size: 25px;
        text-align: center;
      }
    }

    ul, li {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.02em;
      color: #003A5A;

      @include phone {
        font-size: 14px;
        line-height: 17px;
      }
    }

    ul {
      margin-top: 48px;
      margin-bottom: 55px;

      @include phone {
        margin: 32px 0;
      }
    }

    li {
      position: relative;
      padding-left: 28px;
      margin-bottom: 17px;

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        content: url("../img/list-arr-a-serv.svg");
        position: absolute;
        left: 0;
        top: 4px;
      }
    }

  }
  &__right {
    width: 35%;
    display: flex;

    @include media(1244px) {
      width: 100%;
      display: block;
    }
  }

  &__right-container {
    //height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__other-serv {
    min-height: 701px;
    padding: 58px 40px;
    height: 50%;

    position: relative !important;
    z-index: -1;

    @include media(1244px) {
      padding: 58px 33px 65px 166px;
      width: 100% !important;
      min-height: auto;
    }

    @include laptop {
      padding: 58px 40px 66px 40px;
    }

    @include phone {
      padding: 60px 55px;
    }

    &:after {
      content: '';
      position: absolute;
      background: linear-gradient(135deg, #008C95 3.11%, rgba(255, 255, 255, 0) 137.01%), #00C4C4;
      top: 0;
      left: 0;
      height: 100%;
      width: 100vw;

      @include phone {
        box-shadow: 0px 0px 100px rgba(0, 58, 90, 0.2);
        width: 100%;
      }
    }
  }

  &__slider {
    height: 465px !important;

    @include laptop {
      margin-left: -40px;
      width: calc(100% + 80px);
      height: 683px !important;
    }

    @include phone {
      height: auto !important;
      //width: 100%;
      //margin-left: 0;
      padding-bottom: 319px;
    }

    &_in-container {

      @include tablets {
        margin-left: 0;
        width: 100%;
      }
      @include phone {
        margin-left: -40px;
        width: calc(100% + 80px);
      }

    }
  }

  &__h1 {
    font-weight: 600;
    font-size: 35px;
    line-height: normal;
    letter-spacing: 0.02em;
    color: #003A5A;
    margin-bottom: 32px;

    @include phone {
      font-size: 25px;
      text-align: center;
    }

    &_left {
      @include phone {
        text-align: left !important;
      }
    }

    &_white {
      color: white;

      @include media(1244px) {
        width: 100%;
      }
    }
  }

  &__news {
    padding: 58px 40px;
    height: 50%;
    position: relative;

    @include media(1244px) {
      padding: 58px 33px 65px 166px;
    }

    @include laptop {
      padding: 58px 40px 66px 40px;
    }

    @include phone {
      padding: 55px 27px 28px 35px !important;
    }

    &:after {
      content: '';
      position: absolute;
      background: linear-gradient(147.27deg, #00BADD 1.83%, rgba(255, 255, 255, 0) 133.53%), #0573A2;

      top: 0;
      left: 0;
      height: 100%;
      width: 100vw;

      @include tablets {
        display: none;
      }
    }

    .news__left-container {
      z-index: 1;
      position: relative;
      max-width: 352px;

      @include media(1244px) {
        max-width: 688px;
      }
    }
  }

  &__text {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #003A5A;

    @include phone {
      font-size: 14px;
      line-height: 17px;
    }

    p {
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.02em;
      color: #003A5A;
      margin-bottom: 20px;
      margin-top: 0;

      @include phone {
        font-size: 14px;
        line-height: 17px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  //&__list, ul, li {
  //  font-weight: 500;
  //  font-size: 18px;
  //  line-height: 22px;
  //  letter-spacing: 0.02em;
  //  color: #003A5A;
  //
  //  @include phone {
  //    font-size: 14px;
  //    line-height: 17px;
  //  }
  //}

  //&__list {
  //  margin-top: 48px;
  //  margin-bottom: 55px;
  //
  //  @include phone {
  //    margin: 32px 0;
  //  }
  //}
  //
  //&__item {
  //  position: relative;
  //  padding-left: 28px;
  //  margin-bottom: 17px;
  //
  //  &:last-child {
  //    margin-bottom: 0;
  //  }
  //
  //  &:before {
  //    content: url("../img/list-arr-a-serv.svg");
  //    position: absolute;
  //    left: 0;
  //    top: 4px;
  //  }
  //}

  &__highlight {
    margin-bottom: 58px;

    @include phone {
      margin-left: -20px;
      width: calc(100% + 40px);
      margin-bottom: 50px;
    }
  }

}
