.search {
  position: relative;

  &__container {
    display: flex;
  }
  &__field {
    width: 350px;
    position: absolute;
    right: 27px;
    top: 50%;
    transform: translateY(-50%);
    display: none;

    @include nav-tablet {
      display: block;
      position: static;
      top: auto;
      transform: none;
      width: auto;

      background: transparent !important;
    }

    @include media(1024px) {
      width: 350px;
      position: relative;
    }

    @include phone {
      width: auto;
      position: static;
    }
  }

  &__input {
    border-radius: 4px;
    border: 1px solid #E7E7E7;
    padding: 10px 30px 10px 10px;
    position: relative;
    width: 100%;
    font-size: 16px;
    outline: none;

    @include nav-tablet {
      background-color: transparent;
      color: white;
      //background: transparent;
    }

    @include placeholder {
      font-size: 16px;

      @include nav-tablet {
        color: rgba(white, 0.5);
      }
    }
  }

  &__button-inside {
    position: absolute;
    top: 28%;
    cursor: pointer;
    right: 0;
    background-color: transparent;
    border: none;
    outline: 0;
    width: 31px;
    transform: scale(0.7);
    padding: 0;

    @include nav-phone {

        top: 9%;
    }


    @media not all and (min-resolution:.001dpcm)
    { @supports (-webkit-appearance:none) {
        top: 28%;
    }}

    &:hover {
      svg {
        transition: 0.4s;
        transform: scale(1.5);
      }
    }

    svg {
      transition: 0.4s;
      transform: scale(1.3);
      stroke: #C4C4C4;

      circle {
        stroke: #C4C4C4;

        @include nav-tablet {
          stroke: white;
        }
      }

      line {
        stroke: #C4C4C4;

        @include nav-tablet {
          stroke: white;
        }
      }

      path {
        stroke: #C4C4C4;

        @include nav-tablet {
          stroke: white;
        }
      }
    }
  }

  &__icon {
    position: relative;
    width: 17px;
    height: 21px;
    cursor: pointer;

    @include nav-tablet {
      display: none;
    }
  }

  &__find {
    //display: none;
    top: 0px;
    left: 0px;
    position: absolute;
    svg {
      pointer-events: none;
    }
  }
  &__close {
    display: none;
    svg {
      pointer-events: none;
    }
  }
}
