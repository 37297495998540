.carousel {
  &__item {
    min-height: 89px;
    padding-left: 40px;
    @include proximaFont;
    font-weight: 300;
    font-size: 25px;
    line-height: 99.3%;
    letter-spacing: 0.02em;
    color: white;
    transition: .5s ease;
    opacity: .5;
    outline: 0;
    display: flex !important;
    align-items: center;

    @include laptop {
      display: block !important;
      min-height: auto;
      width: 33.33333333%;
      padding-left: 0;
      font-size: 22px;
      padding-right: 40px;
      text-align: center;
    }

    @include tablets {
      padding-right: 0;
    }

    &.slick-current {
      transition: .5s ease;
      opacity: 1;
      position: relative;

      transform: scale(1.1) translateX(10px);
      @include laptop {
        transform: none;
      }

      &:before {
        content: url("../img/left-deco-el.svg");
        width: 16px;
        height: 18px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;

        @include laptop {
          display: none;
        }
      }

    }
  }
}

.carousel_about {
  &__item {
    min-height: 89px;
    padding-left: 40px;
    @include proximaFont;
    font-weight: 300;
    font-size: 25px;
    line-height: 99.3%;
    letter-spacing: 0.02em;
    color: white;
    transition: .5s ease;
    opacity: .5;
    outline: 0;
    display: flex !important;
    align-items: center;

    @include laptop {
      display: block !important;
      min-height: auto;
      width: 33.33333333%;
      padding-left: 0;
      font-size: 22px;
      padding-right: 40px;
      text-align: center;
    }

    @include tablets {
      padding-right: 0;
    }

    &.slick-current {
      transition: .5s ease;
      opacity: 1;
      position: relative;

      transform: scale(1.1) translateX(10px);
      @include laptop {
        transform: none;
      }

      &:before {
        content: url("../img/left-deco-el.svg");
        width: 16px;
        height: 18px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;

        @include laptop {
          display: none;
        }
      }

    }
  }
}
