.link-to {
  display: inline-block;
  margin-right: 23px;
  position: relative;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.02em;
  padding-bottom: 0;
  border-bottom: 1px solid white;
  color: #FFFFFF;
  transition: 0.3s;


  &:hover {
    border-bottom-color: transparent;
  }

  &:after {
    position: absolute;
    content: url("../img/right-arr.svg");
    top: 1px;
    right: -23px;
  }

  &_blue {
    color: #003A5A;
    border-bottom: 1px solid #003A5A;

    &:hover {
      border-bottom-color: transparent;
    }

    &:after {
      content: url("../img/right-arr-blue.svg");
    }
  }
}
