.footer-list {
  display: flex;
  justify-content: space-between;

  @include tablets {
    display: block;
  }

  &__left {
    width: 30%;

    @include tablets {
      width: 100%;
    }

    .footer-list__item {
      &:last-child {
        margin-bottom: 0;

        @include tablets {
          margin-bottom: 25px;
        }
      }
    }
  }

  &__right {
    width: 70%;
    padding-right: 100px;


    @include laptop {
      padding-right: 50px;
    }

    @include tablets {
      width: 100%;
    }

    .footer-list__item {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__item {
    margin-bottom: 25px;
    margin-right: 10px;

    a {
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.02em;
      color: #FFFFFF;
    }
  }
}
