.copyright {

  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-end;

  @include tablets {
    width: 50%;
  }

  @include phone {
    width: 100%;
  }

  &__line {
    width: 100%;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    display: block;
  }
}
