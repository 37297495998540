.m-info {
  padding: 60px 0 70px;

  @include phone {
    padding: 51px 0 57px;
  }
  &__title {
    font-weight: 600;
    font-size: 35px;
    letter-spacing: 0.02em;
    color: #003A5A;
    margin-bottom: 38px;

    @include phone {
      font-size: 25px;
      margin-bottom: 29px;
      text-align: center;
    }
  }
  h2 {
    font-weight: 600;
    font-size: 35px;
    letter-spacing: 0.02em;
    color: #003A5A;
    margin-bottom: 38px;

    @include phone {
      font-size: 25px;
      margin-bottom: 29px;
      text-align: center;
    }
  }

  &__docs-list {
    @include phone {
      margin-left: 20px;
    }
  }
  &__doc-item {
    display: block;
    position: relative;
    padding-left: 39px;
    margin-bottom: 41px;

    @include phone {
      margin-bottom: 37px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: url("../img/download-icon.svg");
      position: absolute;
      left: 0;
      bottom: -6px;
    }
  }

  &__doc-link {
    display: inline;
    font-weight: 600;
    font-size: 22px;
    line-height: 25px;
    letter-spacing: 0.02em;
    padding-bottom: 0;
    border-bottom: 1px solid rgba(#003A5A, 0.5);
    color: #003A5A;

    @include phone {
      font-size: 20px;
      line-height: 22px;
    }

    &:hover {
      border-bottom-color: transparent;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    @include phone {
      display: block;
    }
  }

  &__left {
    width: calc(50% - 20px);
    margin-right: 20px;

    @include phone {
      margin-bottom: 55px;
      width: 100%;
      margin-right: 0;
    }
  }

  &__right {
    width: 50%;
    @include phone {
      width: 100%;
    }
  }

  &__h3 {
    font-weight: 600;
    font-size: 22px;
    letter-spacing: 0.02em;
    color: #003A5A;
    margin-bottom: 15px;

    @include phone {
      font-size: 20px;
      line-height: 24px;
      margin-left: 20px;
      margin-bottom: 21px;
    }
  }

  &__text {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #003A5A;

    a {
      color: #003A5A;
    }

    @include phone {
      font-size: 14px;
      line-height: 17px;
      margin-left: 20px;
    }

    p {
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.02em;
      color: #003A5A;
      margin: 0;
      padding: 0;
      @include phone {
        font-size: 14px;
        line-height: 17px;
      }
    }
  }

  &__form {
    display: flex;
    justify-content: space-between;

    @include media(1245px) {
      display: block;
    }

    @include phone {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .m-info__input {
      -webkit-appearance: none;
      border-radius: 0;
      width: 50%;
      max-width: 350px;
      padding-right: 34px;

      @include media(1245px) {
        width: 100%;
        margin-bottom: 35px;
      }

    }
  }

  &__input {
    border: none;
    border-bottom: 1px solid #003A5A;
    padding: 10px 30px 10px 10px;
    position: relative;
    width: 100%;
    font-size: 18px;
    outline: none;

    @include placeholder {
      color: #003A5A;
      font-size: 18px;
    }
  }
}
