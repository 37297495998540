.card {
  &_news {
    .card {
      &__date {
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.02em;
        color: #FFFFFF;
        margin-bottom: 17px;
      }
      &__name {
        display: inline;
        font-size: 22px;
        line-height: 27px;
        letter-spacing: 0.02em;
        margin-bottom: -2px;
        color: #FFFFFF;
        padding-bottom: 0;
        border-bottom: 1px solid white;
        transition: 0.3s;

        &:hover {
          border-bottom-color: transparent;
        }
      }
    }
  }

  &_4 {
    .card {
      &__name {
        font-size: 16px;
        line-height: 22px;
      }
      &__img {
        padding-top: 84%;
        margin-bottom: 25px;
      }
      &__date {
        margin-bottom: 9px;
      }
    }

  }

  &_video {
    .card {
      &__link {
        &:hover {
          .card__play {
            svg {
              transform: scale(1.2);
              transition: all .25s linear;
            }
          }
        }
      }
      &__img {
        &:after {
          content: '';
          position: absolute;
          z-index: 1;
          background: linear-gradient(180deg, rgba(0, 58, 90, 0) 0%, #003A5A 100%);
          mix-blend-mode: multiply;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &_photo {
    .card {
      &__img {
        padding-top: 71%;
        &:after {
          content: '';
          position: absolute;
          z-index: 1;
          background: linear-gradient(180deg, rgba(0, 58, 90, 0) 0%, #003A5A 100%);
          mix-blend-mode: multiply;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 50%;
        }
      }
    }
  }

  &__link {
    display: block;
    position: relative;
  }

  &__photos {
    font-size: 14px;
    letter-spacing: 0.02em;
    opacity: 0.8;
    z-index: 2;
    position: absolute;
    color: white;
    bottom: 20px;
    left: 59px;
    width: 86%;

    &:before {
      content: url("../img/folder.svg");
      position: absolute;
      left: -33px;
      top: 0;
    }

  }

  &__descr {
    z-index: 2;
    position: absolute;
    color: white;
    bottom: 20px;
    left: 25px;
    width: 86%;
    font-size: 18px;
    @include phone {
      left: 15px;
      bottom: 10px;
      font-size: 16px;
    }
  }

  &__img {
    padding-top: 60%;
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;
    transition: all .25s linear;

    img {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 100%;
      transition: all .25s linear;
    }

    @include phone {
      margin-bottom: 25px;
    }
  }

  &__play {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 50px;
    max-height: 50px;
    width: 90px;
    transform: translate(-50%, -50%);
    z-index: 2;
    //opacity: 0;
    pointer-events: none;
    border-color: #fff;
    color: #81d1ea;
    background-color: transparent;
    transition: all .25s linear;
    svg {
      transition: all .25s linear;
    }
  }

  &__comment {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #003A5A;
  }

}
