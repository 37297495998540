.a-serv-hero {
  min-height: 768px;
  position: relative;

  &_info {
    background-color: black;
    min-height: 500px;

    @include laptop {
      min-height: 768px;
    }
    @include phone {
      min-height: 550px;
    }

    .a-serv-hero {
      &__descr {
        max-width: 745px;

        @include phone {
          position: absolute;
          bottom: 0;
        }

        &_info {
          @include phone {
            max-width: none;

            .a-serv-hero__top-bg {
              width: 120%;
            }
          }
        }
      }
      &__heading-sect {
        @include tablets {
          margin-bottom: 100px;
        }

        @include phone {
          margin-bottom: 73px;
        }
      }
      &__bg-img {
        @include phone {
          height: 550px;
        }
      }
      &__h1 {
        @include phone {
          font-size: 40px;
          line-height: normal;
          max-width: 90%;
          text-align: center;
        }
      }

      &__top {
        padding: 23px 24px 12px 33px;
        @include phone {
          padding: 24px 3px 1px 63px;
        }
      }

      &__info {
        @include phone {
          max-width: 70%;
        }
      }

    }
  }

  &_proj {
    @include phone {
      height: 278px;
      min-height: 278px;

      .project-slider {
        height: 278px;
      }
    }
  }

  &__container {
    @include phone {
      padding: 0;
    }
  }

  &__bg-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;

    @include phone {
      position: static;
      height: 224px;
    }
  }

  &__descr {
    max-width: 545px;
    padding-top: 107px;
    padding-bottom: 108px;

    @include laptop {
      margin: 0 auto;
    }

    @include phone {
      padding: 0;
    }
  }

  &__top {
    position: relative;
    padding: 23px 24px 40px 33px;

    @include phone {
      padding: 23px 23px 36px 23px;
    }
  }

  &__top-bg {
    background: linear-gradient(157.57deg, #008C95 3.11%, rgba(255, 255, 255, 0) 137.01%), #00C4C4;
    opacity: 0.9;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &__h1, &__icon, &__text {
    position: relative;
    z-index: 1;
  }

  &__numb-list {
    @include phone {
      .numbers__item {
        text-align: left !important;
      }
    }
  }

  &__h1 {
    font-weight: 300;
    font-size: 45px;
    line-height: 47px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-right: 20px;

    @include phone {
      margin-right: 0;
      font-size: 32px;
      line-height: 34px;
      order: 2;
    }
  }

  &__heading-sect {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 36px;

    @include phone {
      flex-direction: column;
    }
  }

  &__text {
    font-size: 22px;
    line-height: 27px;
    letter-spacing: 0.02em;
    color: #FFFFFF;

    @include phone {
      font-size: 18px;
      line-height: 22px;
      text-align: center;
    }
  }

  &__bot {
    background-color: rgba(#003A5A, 0.5);
    padding: 27px 55px 29px 33px;
    position: relative;
    z-index: 1;

   @include phone {
     background-color: #003A5A;
     padding:36px 30px 32px;
   }
  }

  &__items {
    display: flex;

    @include phone {
      display: block;
    }
  }

  &__info {
    position: relative;
    z-index: 1;
    font-size: 18px;
    letter-spacing: 0.02em;
    color: #FFFFFF;

    @include phone {
      font-size: 16px;
      max-width: 60%;
    }
  }

  &__item-icon {
    width: 33.333%;
    max-width: 225px;
    padding-right: 15px;

    @include phone {
      width: 100%;
      max-width: none;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }

    &:last-child {
      padding-left: 0;
    }
  }
}
