.work-format {
  min-height: 768px;

  &__heading {
    margin-left: 157px;
    margin-top: 75px;
    margin-bottom: 78px;
    position: relative;

    @include media(1376px) {
      //margin-bottom: 28px;
    }

    @include media(1333px) {
      margin-bottom: 113px;
    }
    @include media(1300px) {
      margin-bottom: 82px;
    }

    @include media(1216px) {
      margin-bottom: 113px;
    }

    @include media(1179px) {
      margin: 0;
      padding: 0;
      margin-bottom: 37px;
    }

    @include phone {
      margin-bottom: 20px;
      text-align: center;
    }
  }

  &__deco-line {
    margin-left: 157px;

    @include media(1179px) {
      display: none;
    }
  }
  &__container {
    position: relative;
    display: flex;
    overflow: hidden;
    min-height: 768px;
    width: 100%;

    @include media(1179px) {
      display: block;
      padding: 79px 40px 126px;
    }

    @include phone {
      padding: 77px 30px 90px;
    }
  }

  &__formats-list {

  }

  &__left {
    //width: 50%;
    //width: 44%;
    //position: relative;
    //padding-right: 26px;
    //min-width: 601px;

    width: calc(44% - 54px);
    position: relative;
    margin-right: 54px;
    min-width: 601px;

    @include media(1179px) {
      width: 100%;
      min-width: auto;
      padding-right: 0;
    }

    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90.01deg, #003A5A 0.01%, rgba(255, 255, 255, 0) 99.99%);
      mix-blend-mode: multiply;

      @include media(1179px) {
        display: none;
      }

    }
  }

  &__right {
    width: calc(56% - 167px);
    margin: 90px 167px 103px 0;
    @include media(1300px) {
      margin: 90px 50px 103px 0;
      width: calc(56% - 50px);
    }

    @include media(1179px) {
      width: 100%;
      margin: 0;
    }

    @include media(1179px) {
      //padding: 0 40px;
      margin: 0;
    }
  }

  &__background {
    z-index: -1;
    position: absolute;
    background-color: #00C4C4;
    display: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;

    @include media(1179px) {
      &:before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 60%;
        height: 100%;
        background: linear-gradient(90.01deg, #003A5A 0.01%, rgba(255, 255, 255, 0) 99.99%);
        mix-blend-mode: multiply;
      }
    }
    &.active {
      display: block;
    }
  }

}
