.info {
  position: relative;

  &:after {
    content: url("../img/egg-info.png");
    position: absolute;
    left: 0;
    top: 91px;
    //z-index: 1;

    @include laptop {
      content: url("../img/egg-info-tablet.png");
      top: 567px;
    }

    @include tablets {
      display: none;
    }
  }

  &_right-egg {
    .info__text {
      a {
        text-decoration-line: underline;
        color: #003A5A;

        &:hover {
          text-decoration-color: transparent;
        }
      }
    }

    .info__content {
      padding-top: 99px;
    }

    .info__nav {
      @include laptop {
        margin-top: 0;
        padding-left: 0;
        padding-right: 0;

        &:after {
          left: -47px;
          width: calc(100% + 94px);
        }
      }

      @include phone {
        padding-top: 29px;
      }

    }
    &:after {
      content: url("../img/right-project-egg.png");
      position: absolute;
      right: 0;
      left: auto;
      top: 100px;

      @include laptop {
        content: url("../img/right-project-egg.png");
        top: 100px;
      }

      @include tablets {
        display: block;
        content: url("../img/project-egg-mobile.png");
        top: 267px;
      }

    }
  }
  &__h1 {
    font-weight: 600;
    font-size: 35px;
    line-height: normal;
    letter-spacing: 0.02em;
    color: white;
    margin-bottom: 44px;

    &_blue {
      color: #003A5A;
    }

    @include phone {
      font-size: 25px;
      text-align: center;
      margin-bottom: 25px;
    }
  }

  &__h2, h2 {
    font-weight: 600;
    font-size: 22px;
    line-height: 99.3%;
    letter-spacing: 0.02em;
    color: #003A5A;
    margin-bottom: 21px;

    @include phone {
      font-size: 18px;
    }
  }

  &__link {
    margin-left: 14px;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-decoration-line: underline;

    color: #0573A2;
    font-weight: normal;

    @include laptop {
      font-size: 16px;
      line-height: 19px;
    }

    @include tablets {
      font-size: 14px;
      line-height: 17px;
    }
  }

  &__text {
    //margin-bottom: 20px;
    margin-bottom: 28px;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #003A5A;

    @include phone {
      font-size: 14px;
      line-height: normal;
    }

    &_warn {
      position: relative;
      padding-left: 43px;
      margin-bottom: 30px;

      &:before {
        width: 3%;
        height: 76%;
        left: 0;
        content: '';
        @include centered(y);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("../img/warn-sign.svg");

        @include phone {
          top: 30px;
        }
      }
    }

    p {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__highlight {
    background: #F1F1F1;
    border-radius: 4px;
    position: relative;
    padding: 10px 15px 10px 25px;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #003A5A;
    margin-bottom: 20px;

    @include phone {
      font-size: 14px;
      line-height: normal;
    }

    p {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:before {
      position: absolute;
      background: linear-gradient(104.38deg, #00BADD 1.83%, rgba(255, 255, 255, 0) 133.53%), #0573A2;
      content: '';
      left: 0;
      top: 0;
      height: 100%;
      width: 10px;
    }
  }
  &__slider {
    margin-bottom: 55px;

    @include phone {
      .slider-bot {
        &__descr {
          position: absolute;
          z-index: 23333;
          bottom: 0;
          margin-left: 49px;
          margin-bottom: 20px;
          max-width: 300px;
          width: auto;
          min-height: auto;
          text-align: left;
          top: auto;
          background: transparent;
          padding: 0;
          padding-top: 30px;
          box-shadow: none;
        }

        &__title {
          font-size: 22px;
          line-height: normal;
          margin-bottom: 0;
        }

        &__background {
          &:after {
            display: block;
          }
        }

        &__video-wrap {
          @include phone {
            &:after {
              display: block;
            }
          }
        }
      }

      padding-bottom: 89px;
      background: transparent;

      .media-slider__controls {
        bottom: -64px;

        svg {
          rect {
            stroke: #0C2D3A;
          }
          path {
            stroke: #0C2D3A;
          }
        }
      }
    }
  }
  &__container {
    display: flex;
    flex-flow: column wrap;
    flex: auto;
    padding: 0 159px;
    //padding-bottom: 80px;
    //min-height: 1700px;

    @include media(1349px) {
      padding: 0 59px;
    }

    @include laptop {
      min-height: auto;
      flex-wrap: nowrap;
      padding-bottom: 0;
    }
  }
  &__content {
    height: 100%;
    width: 69%;
    //min-height: 1000px;
    padding-top: 55px;
    padding-right: 34px;

    @include laptop {
      width: 100%;
      order: 2;
      min-height: auto;
      padding-right: 0;
    }

    @include phone {
      padding-top: 23px;
    }

    ul, li {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.02em;
      color: #003A5A;

      @include phone {
        font-size: 14px;
        line-height: 17px;
      }
    }

    ul {
      margin-top: 48px;
      margin-bottom: 55px;

      @include phone {
        margin: 32px 0;
      }
    }

    li {
      position: relative;
      padding-left: 37px;
      margin-bottom: 25px;

      @include phone {
        margin-bottom: 16px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        content: url("../img/list-arr-a-serv.svg");
        position: absolute;
        left: 0;
        top: 4px;
      }
    }

  }

  &__warn {
    border: 2px solid #F1F1F1;
    border-radius: 4px;
    padding: 32px 40px 35px;
    margin-bottom: 35px;

    @include phone {
      padding: 19px 20px 35px;
    }

    ul {
      margin: 0;
    }
  }

  &__doc-item {
    position: relative;
    display: block;
    padding-left: 40px;

    margin-left: 0 !important;
    margin-bottom: 25px;

    &:before {
      left: 0;
      top: 0;
      content: url("../img/download-info-icon.svg");
      position: absolute;

    }
  }
  &__docs-list {
    margin-bottom: 55px;

    a {
      margin-left: 14px;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.02em;
      text-decoration-line: underline;

      color: #0573A2;
      font-weight: normal;

      @include laptop {
        font-size: 16px;
        line-height: 19px;
      }

      @include tablets {
        font-size: 14px;
        line-height: 17px;
      }
    }
  }

  &__quotation {
    @include phone {
      margin-left: -14px;
      width: calc(100% + 28px);
    }
  }

  &__table, table {
    border-spacing: 0 2px;
    margin-bottom: 35px;

    tr {
      background: rgba(#33B4D3, 0.05);
      margin-bottom: 2px;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.02em;
      color: #003A5A;

      @include phone {
        font-size: 14px;
        line-height: normal;
      }
      &:nth-child(odd) {
        background: rgba(#33B4D3, 0.15);
      }
    }

    td {
      p {
        a {
          color: #003A5A;
          margin-left: 0;
          text-decoration: none;
        }
      }
    }

    td:not(:first-child) {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 25px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    td:first-child {
      font-weight: 600;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 25px;
      padding-right: 20px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      width: 332px;

      @include phone {
        padding-left: 14px;
      }
    }

  }

  &__circles-block {
    margin-bottom: 55px;

    @include phone {
      margin-bottom: 0;
    }
  }

  &__nav {
    width: 31%;

    @include laptop {
      width: 100%;
      order: 1;
      margin-top: -116px;
    }

    @include tablets {
      padding: 40px 50px 40px 40px;
    }
    @include phone {
      margin-top: 0;
      padding: 40px 15px 5px 15px;
    }
  }
  &__news {
    background-color: blueviolet;
    width: 31%;
    position: relative;
    padding: 55px 0 55px 44px;

    @include phone {
      padding: 55px 0 55px 6px !important;
    }

    &.news {
      background: none;
    }

    &:after {
      content: '';
      position: absolute;
      background: linear-gradient(147.27deg, #00badd 1.83%, rgba(255, 255, 255, 0) 133.53%), #0573a2;
      top: 0;
      left: 0;
      height: 100%;
      width: 100vw;
      z-index: -1;

      @include laptop {
        left: -47px;
        height: 100%;
        width: calc(100% + 100px);
      }
    }

    @include laptop {
      width: 100%;
      order: 3;
    }
  }

  &__attention {
    margin-bottom: 25px;
  }

  &__ol, ol {
    counter-reset: list1; /* Инициируем счетчик */
    list-style: none;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0;
    column-count: 2;
    column-gap: 45px;
    margin-bottom: 35px;

    @include phone {
      column-count: 1;
      column-gap: 0;
    }
  }

  &__ordered-item, ol li {
    &:before {
      counter-increment: list1; /* Увеличиваем значение счетчика */
      content: counter(list1) ". "; /* Выводим число */
      top: 0;
      font-weight: 600;
      font-size: 22px;
      letter-spacing: 0.02em;
      color: #00C4C4;

      @include phone {
        font-size: 14px;
      }
    }
    padding-left: 35px;
    margin-bottom: 25px;

    @include phone {
      margin-bottom: 17px;
    }

  }

  &__projects {
    margin-bottom: 25px;

    @include phone {
      margin-left: -14px;
      width: calc(100% + 28px);
    }
  }

  &__proj-overview {
    max-width: 928px;
    position: absolute;
    z-index: 1;
    //top: -121px;
    bottom: 98%;
    padding: 35px;
    color: white;
    background: linear-gradient(173.74deg, #008C95 3.11%, rgba(255, 255, 255, 0) 137.01%), #00C4C4;
    border-radius: 4px;

    @include laptop {
      width: calc(100% - 94px);
      max-width: none;
    }

    @include tablets {
      position: static;
      width: calc(100% + 32px);
      margin-left: -14px;
      border-radius: 0;
    }

    @include phone {
      padding: 30px 29px 30px 14px;
    }
  }

  &__proj-date {
    font-size: 18px;
    margin-bottom: 5px;

    @include phone {
      font-size: 14px;
    }
  }

  &__proj-name {
    font-weight: 300;
    font-size: 35px;
    letter-spacing: 0.02em;
    line-height: 99.3%;
    color: #FFFFFF;
    @include phone {
      word-break: break-word;
      font-size: 32px;
    }
  }

  &__pic-wrapper {
    padding-top: 67%;
    overflow: hidden;
    position: relative;
    margin-bottom: 35px;
    transition: all .25s linear;

    @include phone {
      margin-left: -32px;
      width: calc(100% + 64px);
      padding-top: 80%;
    }

    img {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 100%;
      transition: all .25s linear;
    }

  }
}

.warn__list {
  .info__link {
    @include phone {
      display: block;
      margin-left: 0;
      margin-top: 8px;
    }
  }
}
