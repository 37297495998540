.number {
  display: flex;
  align-items: center;

  @include phone {
    justify-content: center;
  }
  &__pic {
    margin-right: 10px;
    position: relative;

    @include phone {
      margin-right: 11px;
    }

    & > svg {
      animation: rotate 1.4s linear infinite;

    }

  }

  &__inner-pic {
    position: absolute;
    top: 13px;
    left: 14px;

    &_3 {
      top: 17px;
      left: 11px;
    }
  }

  &__num {

  }
  &__figures {
    width: 140px;
    font-weight: 600;
    font-size: 48px;
    line-height: 55px;
    letter-spacing: 0.02em;
    color: #FFFFFF;

    margin-bottom: 0;

    @include phone {
      line-height: 53px;
    }
  }
  &__under-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    text-transform: uppercase;

    color: #FFFFFF;
  }

  &_about {
    .number__inner-pic {
      top: 20px;
      left: 19px;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate-bw {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
