.mobile-menu {
    display: none;
    height: 23px;

  &:hover {
    cursor: pointer;
  }

    @include nav-tablet {
      display: inherit;
      position: relative;
      width: 34px;
      z-index: 12;
    }
    &__top {

      position: absolute;
      width: 34px;
      height: 3px;
      left: 0;
      top: 0;
      background: #FFFFFF;
      //transition: transform 0.7s;
      transition: 0.7s;
    }

    &__middle {
      position: absolute;
      width: 34px;
      height: 3px;
      left: 0;
      top: 10px;
      background: #FFFFFF;
      opacity: 1;
      //transition: 0.9s;
      transition: transform 0.7s;

    }

    &__bottom {
      position: absolute;
      width: 34px;
      height: 3px;
      left: 0;
      top: 20px;
      background: #FFFFFF;
      opacity: 1;
      //transition: 0.9s;
      transition: transform 0.7s;
    }


  &.active {
    .mobile-menu__top {
      opacity: 0;
    }
    .mobile-menu__middle {
      transform: rotate(45deg);
      width: 25px;
      left: 3px;
      top: 13px;
    }
    .mobile-menu__bottom {
      transform: rotate(135deg);
      width: 25px;
      left: 3px;
      top: 13px;
    }
  }

}
