.pin {
  font-family: "Proxima Nova Rg", sans-serif;
  position: absolute;
  //z-index: 2;
  z-index: unset;

  &.active {
    z-index: 999;
  }

  @include tablets {
    z-index: unset;
  }

  &#afip {

  }

  &#msc {
    top: 32%;
    left: 11%;

    @include phone {
      top: 25%;
      left: 9%;
    }
  }

  &#baltic {
    left: 13%;
    top: 17%;

    @include media(767px) {
      left: 14%;
      top: 19%;
    }

    @include media(500px) {
      left: 11%;
      top: 11%;
    }
  }

  //#yamal {
  //
  //}

  &#arctic {

  }

  &#arctic-2 {
    top: 39%!important;
    left: 41%!important;

    @include phone {
      top: 36%!important;
      left: 39%!important;
    }
  }

  &#amur-gpz {
    left: 70%;
    top: 62%;

    @include media(767px) {
      top: 65%;
    }

    @include media(500px) {
      top: 55%;
    }
  }

  &#amur-ghk {
    top: 68%;

    @include phone {
      top: 64%;
    }
  }

  &#zapsib {
    left: 29%;
    top: 53%;

    @include media(767px) {
      top: 56%;
    }
    @include media(500px) {
      top: 46%;
    }

  }
  &#omsk {
    left: 32%;
    top: 63%;

    @include phone {
      left: 31%;
      top: 56%;
    }
  }

  &_hydrocarbon {
    top: 29%;
    left: 39%;

    @include phone {
      top: 24%;
      left: 37%;
    }

    .pin {
      &__icon {
        top: 16px;
        left: 24px;

        @include laptop {
          top: 6px;
          left: 15px;
        }

        @include tablets {
          top: 2px;
          left: 13px;
        }
      }

      &__pic {
        & > svg {
          fill: #F18700;
        }
      }
    }
  }
  &_oil {
    top: 48%;
    left: -1%;
    .pin {
      &__icon {
        top: 18px;
        left: 24px;

        @include laptop {
          top: 8px;
          left: 16px;
        }

        @include tablets {
          top: 3px;
          left: 13px;
        }
      }

      &__pic {

        & > svg {
          g {
            circle {
              fill: #00C4C4 !important;
            }

            path {
              fill: #00C4C4 !important;
            }
          }

        }
      }
    }
  }

  &_gas {
    top: 53%;
    left: 30%;
    .pin {
      &__icon {
        top: 18px;
        left: 21px;

        @include laptop {
          top: 8px;
          left: 16px;
        }

        @include tablets {
          top: 3px;
          left: 13px;
        }
      }

      &__pic {

        & > svg {
          g {
            circle {
              fill: #00BADD !important;
            }

            path {
              fill: #00BADD !important;
            }
          }

        }
      }
    }
  }

  &_oil-gas {
    top: 67%;
    left: 74%;
    .pin {
      &__icon {
        top: 19px;
        left: 21px;

        @include laptop {
          top: 8px;
          left: 16px;
        }

        @include tablets {
          top: 4px;
          left: 13px;
        }
      }

      &__pic {
        & > svg {
          g {
            circle {
              fill: #008C95 !important;
            }

            path {
              fill: #008C95 !important;
            }
          }
        }
      }
    }
  }

  &__pic {
    position: relative;
    //z-index: 3;
    z-index: unset;
    width: 66px;
    height: 75px;

    & > svg {
      z-index: 87;
      position: relative;
    }

    @include laptop {
      width: 42px;
      height: 50px;
    }

    @include tablets {
      width: 34px;
      height: 41px;
      z-index: unset;

      svg {
        position: relative;
        //z-index: unset;
        z-index: 1;
      }
    }

    //&_hydrocarbon {
    //  & > svg {
    //    fill: #F18700;
    //  }
    //}
  }

  &__icon {
    position: absolute;

    z-index: 88;
    @include laptop {
      svg {
        width: 12px;
      }
    }

    @include tablets {
      z-index: unset;
      svg {
        width: 9px;
      }
    }

    //&_hydrocarbon {
    //  top: 16px;
    //  left: 24px;
    //}
  }
}
