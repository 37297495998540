.btn {
  outline: none;
  border: none;
  display: block;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  padding: 12px 35px;
  background-color: #00BADD;
  border-radius: 4px;
  max-width: 250px;
  transition: 0.4s;
  cursor: pointer;

  &:hover {
    background: #20D5F7;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);

    @include phone {
      background: #00BADD;
      box-shadow: none;
    }
  }

  &_bordered {
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid white;

    &:hover {
      @include phone {
        background: transparent;
        box-shadow: none;
      }
    }
  }

  &_larger {
    @include phone {
      padding: 20px 26px;
    }
  }
}

.prev-slide, .next-slide {
  svg {
    transition: fill 0.4s;
  }

  &:hover {
   svg {
     transition: fill 0.4s;
     fill: white;

     @include phone {
       fill: none;
     }
     path {
       stroke: #008c95 !important;
       @include phone {
         stroke: white !important;
       }
     }
   }
  }
}

.swiper-button-next, .swiper-button-prev {
  svg {
    transition: fill 0.4s;
  }


  &:hover {
    svg {
      transition: fill 0.4s;
      fill: white;

      @include phone {
        fill: none;
      }
      path {
        stroke: #003a5a !important;
        @include phone {
          stroke: white !important;
        }
      }
    }
  }
}

