.procurement-card {
  width: calc(50% - 32px);
  margin-left: 32px;
  margin-bottom: 55px;

  @include tablets {
    margin-bottom: 35px;
    margin-left: 0;
    width: 100%;
  }

  @include phone {
    margin-bottom: 30px;
  }

  &__pic {
    padding-top: 48%;
    overflow: hidden;
    position: relative;
    transition: all .25s linear;

    img {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 100%;
      transition: all .25s linear;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00C4C4;
    font-weight: 600;
    margin-right: 24px;
    margin-left: 24px;
    font-size: 22px;
    line-height: 99.3%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-top: -25px;
    text-align: center;
    padding: 16px;
    position: relative;
    z-index: 1;
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: 0.3s;

    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(172.87deg, #008C95 3.11%, rgba(255, 255, 255, 0) 137.01%);
      opacity: 0.5;
    }

    &:hover {
      text-decoration-color: #fff;
      transition: 0.3s;

    }

    @include laptop {
      min-height: 64px;
    }
  }
}
