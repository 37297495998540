.slider-proj-inner {

  &__item {
    position: relative;

    &_video {
      .slider-proj-inner__pic-wrap {
        display: none;
      }
    }
  }

  &__video-wrap {

    &:after {
      content: '';
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.3);
    }
  }
  &__descr {
    position: absolute;
    z-index: 23333;
    bottom: 0;
    margin-left: 43px;
    margin-bottom: 28px;
    max-width: 300px;
  }
  &__title {
    font-size: 32px;
    line-height: 39px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-bottom: 20px;

  }

  &__subtitle, .slider-proj-inner__date {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }

  &__subtitle {
    max-height: 38px;
    overflow: hidden;
  }

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.3);
    }
  }


  .swiper-slide {
    overflow: hidden;
    height: auto;
  }
}
