.video-bg {
  position: relative;
  height: 700px;
  overflow: hidden;

  @include tablets {
    height: 518px;
  }

  @include phone {
    height: 345px;
  }


  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    z-index: 2;

    background-color: rgba(0, 0, 0, 0.65);
  }

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    //z-index: 1;
    transform: translateX(-50%) translateY(-50%);
    background-size: cover;
    background-position: center;
    transition: 1s opacity;

    @include phone {
      overflow: hidden;
    }
  }


  &__container {
    position: relative;
    z-index: 3;

    @include phone {
      background-color: red;
      position: absolute;
      top: 100%;
      z-index: 5555;
    }

    &_mob {
      display: none;
      @include phone {
        background-color: transparent;
        display: block;
        position: static !important;
        padding: 40px 59px 18px;
      }
      .video-bg {
        &__text {
          @include phone {
            display: block;
            margin: 0;
            margin-bottom: 18px;
          }
        }
      }
    }
  }

  &__text {
    font-weight: 300;
    font-size: 45px;
    line-height: 45px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-top: 129px;
    margin-bottom: 43px;
    max-width: 410px;

    @include tablets {
      font-size: 38px;
      line-height: 38px;
    }

    @include phone {
      font-size: 28px;
      line-height: 28px;

      display: none;
    }
  }
}
