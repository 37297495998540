@mixin retina {
	@media screen and (-webkit-mon-device-pixel-ratio:1.5), screen and (min-resolution: 144dpi){
		@content;
	}
}

@mixin placeholder {
	&::-webkit-input-placeholder { @content; }
	&:-moz-placeholder           { @content; }
	&::-moz-placeholder          { @content; }
	&:-ms-input-placeholder      { @content; }
}

@mixin centered($axis: 'both'){
	position: absolute;

	@if $axis == 'both'{
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	@if $axis == 'x'{
		left: 50%;
		transform:translateX(-50%);
	}

	@if $axis == 'y'{
		top: 50%;
		transform:translateY(-50%);
	}
}

@mixin media($width) {
	@media screen and (max-width: $width) {
		@content;
	}
}

@mixin media-min($width) {
	@media screen and (min-width: $width) {
		@content;
	}
}

//@mixin desktop {
//	@media screen and (max-width:1199px){
//		@content;
//	}
//}

@mixin nav-tablet {
	@media screen and (max-width:1420px){
		@content;
	}
}

@mixin nav-768-tablet {
	@media screen and (max-width:1023px){
		@content;
	}
}


@mixin nav-phone {
	@media screen and (max-width:600px){
		@content;
	}
}


@mixin laptop {
	@media screen and (max-width:1024px){
		@content;
	}
}

@mixin tablets {
	@media screen and (max-width:768px){
		@content;
	}
}

@mixin phone {
	@media screen and (max-width:600px){
		@content;
	}
}

@mixin proximaFont {
	font-family: 'Proxima Nova', 'Helvetica', sans-serif;
}

//colors
$strawberry: #D9007E;
$spindrift: #00B295;
$black: #000000;
$white: #ffffff;
$gray: #808080;
$slider-background: #F7FAF9;


@mixin autofill {
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		@content;
	}
}
