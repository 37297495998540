.project-slider {
  height: 500px;
  background-color: black;

  video {
    position: absolute;
    top: 0;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translateX(-50%);
    background-size: cover;
    -webkit-transition: 1s opacity;
    transition: 1s opacity;

    //@include phone {
    //  transform: none;
    //  position: absolute;
    //  z-index: 1;
    //  top: 0;
    //  left: 0;
    //  width: 100%;
    //  height: 100%;
    //  object-fit: cover;
    //  object-position: 50% 100%;
    //  transition: all .25s linear;
    //}
  }

  @include laptop {
    height: 768px;
  }

  @include tablets {
    height: 768px;
  }

  &__container {
    height: 100%;
    position: relative;
    overflow: hidden;

  }

  &__controls {
    display: flex;
    .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after,
    .swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
      content: '';
    }

    .swiper-button-next:hover svg, .swiper-button-prev:hover svg {
      fill: none;
    }
    .swiper-button-next:hover svg path, .swiper-button-prev:hover svg path {
       stroke: rgba(255,255,255,0.5)!important;
    }

    .swiper-button-next, .swiper-button-prev {
      outline: none;
      position: static;
      top: auto;
      width: auto;
      height: auto;
      margin-top: 0;

    }

    .swiper-button-next {
      margin-left: 41px;
      transform: rotate(180deg);
    }

    .swiper-button-prev {
      right: 125px;
      left: auto;
    }
  }
  &__info {
    align-items: center;
    position: absolute;
    display: flex;
    right: 0;
    margin-right: 3rem;
    margin-bottom: 36px;
    bottom: 0;
    z-index: 1;

    @include media(1224px) {
      margin-bottom: 159px;
    }

    @include phone {
      margin-bottom: 25px;
      margin-right: 27px;
    }
  }
  &__pagination {
    position: static;
    margin-right: 25px;
    font-size: 32px;
    line-height: 99.3%;
    letter-spacing: 0.02em;
    color: #FFFFFF;

    .swiper-pagination-total {
      font-size: 24px;
      color: #FFFFFF;
      opacity: 0.6;
    }
  }
}
