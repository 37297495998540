.numbers {
  width: 100%;
  min-width: 100%;
  position: absolute;
  z-index: 7;
  margin-top: -150px;

  &_flex {
    display: flex;
    margin-top: 0;
    position: relative;
    justify-content: space-between;

    @include phone {
      display: block;
      background: transparent !important;
    }

    .numbers {
      &__item {
        width: auto;
        margin-right: 55px;

        @include phone {
          margin-right: 0;
          padding: 0;
          margin-bottom: 42px;
        }

        &:last-child {
          width: auto;

          @include phone {
            margin-bottom: 0;
          }
        }
      }
      &__text {
        @include laptop {
          height: auto;
        }

        @include phone {
          max-width: 180px;
          margin: 0 auto;
          margin-bottom: 10px;
          text-align: center;
        }
      }
    }

    .number__under-text {
      max-width: 193px;
    }

  }

  @include phone {
    background: linear-gradient(173.09deg, #008C95 3.11%, rgba(255, 255, 255, 0) 137.01%), #00C4C4;
    box-shadow: 0px 0px 100px rgba(0, 58, 90, 0.2);
  }

  @include laptop {
    position: relative;
    margin-top: 0;
  }

  &__container {
    max-width: 1120px;
    border-radius: 4px;
    padding: 39px 75px;
    background: linear-gradient(173.09deg, #008C95 3.11%, rgba(255, 255, 255, 0) 137.01%), #00C4C4;
    box-shadow: 0px 0px 100px rgba(0, 58, 90, 0.2);


    display: flex;
    margin: 0 auto;


    @include media(1089px) {
      border-radius: 0;
    }

    @include laptop {
      //padding: 39px 50px;
      display: block;
    }

    @include phone {
      padding: 0;
      //padding: 39px 90px;
      background: transparent;
      box-shadow: none;

    }
  }


  &__item {
    //min-width: 236px;
    outline: none;
    width: calc(25% - 50px);
    margin-right: 50px;
    @include proximaFont;

    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @include laptop {
      margin-right: 0;
      //display: block;
      width: auto;
      padding: 0 15px;
    }

    @include phone {
      text-align: center;
      padding: 39px 71px;
    }

    &:last-child {
      margin-right: 0;
      width: 25%;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-bottom: 20px;

    @include laptop {
      max-width: 230px;
      height: 52px;
      overflow: hidden;
    }

    @include phone {
      max-width: 100%;
      height: 73px;
    }


  }

  &__number {
    margin-top: auto;
  }

}
