.pin-descr {
  position: absolute;
  top: 18px;
  left: 33px;
  //z-index: -1;

  background-color: rgba(white, 0.9);
  border-radius: 4px;
  min-width: 302px;

  &_left {
    left: auto;
    right: 21px;
  }

  @include tablets {
    display: none;
  }

  @include laptop {
    top: 4px;
    left: 22px;
    min-width: 202px;
  }

  @include phone {
    position: fixed;
    top: 50%;
    width: 90%;
    transform: translateY(-50%);
    padding: 12px 0;
    z-index: 5;
  }

  &__cross {
    display: none;
    @include phone {
      display: block;
      position: absolute;
      top: 12px;
      right: 15px;
      z-index: 6;
    }
  }

  &__text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #003A5A;
    padding:  8px 25px 8px 32px;
    cursor: pointer;



    @include laptop {
      font-size: 18px;
      line-height: 22px;
    }

    @include tablets {
      pointer-events: none;
    }
  }
  &__arr {
    cursor: pointer;
    transition: 0.4s;
    transform: none;
    &.active {
      transform: rotate(-180deg);
    }

    @include tablets {
      display: none;
    }
  }

  &__dropdown {
    display: none;
    padding: 10px 38px 22px 34px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;

    //
    //background-color: rgba(white, 0.9);
    position: relative;
    z-index: 85;

    @include tablets {
      display: block;
      position: static;
    }
  }

  &__dropdown-text {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #003A5A;

    margin-bottom: 19px;

    @include laptop {
      font-size: 14px;
      line-height: 17px;
    }
  }

  &__dropdown-btn {
    //display: block;
    //font-weight: 600;
    //font-size: 18px;
    //line-height: 22px;
    //text-align: center;
    //letter-spacing: 0.02em;
    //color: #FFFFFF;
    //padding: 12px 35px;
    //background-color: #00BADD;
    //border-radius: 4px;


    @include laptop {
      padding: 12px 16px;
      max-width: 250px;
    }
  }
}
