.slider-bot {

  &__item {
    position: relative;

    &_video {
      .slider-bot__pic-wrap {
        display: none;
      }
    }

    //background: linear-gradient(180deg, rgba(0, 58, 90, 0) 0%, #003A5A 100%);


    //@include phone {
    //  padding-top: 56.25%;
    //  overflow: hidden;
    //  position: relative;
    //}
  }

  &__video-wrap {

    &:after {
      content: '';
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0, 58, 90, 0) 0%, #003A5A 100%);
    }

    @include phone {
      padding-top: 56.25%;
      overflow: hidden;
      position: relative;

      &:after {
        display: none;
      }
    }
  }

  &__pic-wrap {
    @include phone {
      padding-top: 56.25%;
      overflow: hidden;
      position: relative;
    }
  }

  &__descr {
    position: absolute;
    z-index: 23333;
    bottom: 0;
    //max-width: 100%;
    margin-left: 43px;
    margin-bottom: 28px;
    max-width: 300px;

    @include phone {
      //position: relative;
      min-height: 319px;
      width: 100%;
      max-width: none;
      text-align: center;
      margin: 0;
      padding: 30px 16px 82px;
      bottom: auto;
      top: 100%;
      background: linear-gradient(160.34deg, #008C95 3.11%, rgba(255, 255, 255, 0) 137.01%), #00C4C4;
      box-shadow: 0px 0px 100px rgba(0, 58, 90, 0.2);
    }
  }
  &__title {
    font-size: 32px;
    line-height: 39px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-bottom: 20px;

    @include phone {
      max-height: 127px;
      overflow: hidden;
    }
  }

  &__subtitle, .slider-bot__date {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }

  &__subtitle {
    max-height: 38px;
    overflow: hidden;
  }

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-size: cover;
    //background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;

    @include phone {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 100%;
      transition: all .25s linear;

      //height: 289px;
      //overflow: hidden;
      &:after {
        display: none;
      }
    }

    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0, 58, 90, 0) 0%, #003A5A 100%);
    }
  }


  .swiper-slide {
    overflow: hidden;
    height: auto;

    @include phone {
      height: 100%;
      overflow: visible;
    }
  }
}
